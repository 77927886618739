import React from 'react';

import { NoSsr } from '@mui/base';
import { useMediaQuery } from '@mui/material';

import { makeStyles } from 'tss-react/mui';

import {
  LoadableSwiperSlider,
  SwiperSliderProps,
} from 'ha/components/SwiperSlider';

import { ListingsSliderCardWithButton } from './ListingsSliderCardWithButton';
import {
  ListingsSliderControlNext,
  ListingsSliderControlPrevious,
} from './ListingsSliderControl';
import { ListingsSliderSkeleton } from './ListingsSliderSkeleton';

const useStyles = makeStyles()(() => ({
  sliderContainer: {
    '--container-height': '', // Initialize the CSS variable
    minHeight: 'var(--container-height)',
    position: 'relative',
  },
}));

interface Props {
  sliderItems: SwiperSliderProps['items'];
  'data-test-locator'?: string;
}

export const ListingsSlider = ({
  sliderItems,
  'data-test-locator': dataTestId,
}: Props) => {
  const { classes, theme } = useStyles();
  const containerRef = React.useRef<HTMLDivElement>(null);
  const containerHeightRef = React.useRef<number | null>(null);

  const isLargerThanLg = useMediaQuery(theme.breakpoints.up('lg'));
  const isLargerThanMd = useMediaQuery(theme.breakpoints.up('md'));
  const isLargerThanSm = useMediaQuery(theme.breakpoints.up('sm'));

  React.useEffect(() => {
    if (containerRef.current && containerHeightRef.current === null) {
      const height = containerRef.current.offsetHeight;
      if (height) {
        containerHeightRef.current = height;
        containerRef.current.style.setProperty(
          '--container-height',
          `${height}px`,
        );
      }
    }
  }, []);

  const sliderPerView = React.useMemo(() => {
    if (isLargerThanLg) return 4;
    if (isLargerThanMd) return 3;
    if (isLargerThanSm) return 2;

    return 1.2;
  }, [isLargerThanLg, isLargerThanMd, isLargerThanSm]);

  const spaceBetween = React.useMemo(() => {
    if (isLargerThanMd) return theme.utils.spacing('ref/spacing/10');

    return theme.utils.spacing('ref/spacing/6');
  }, [isLargerThanMd, theme.utils]);

  const shouldShowControls = sliderItems.length >= sliderPerView;

  return (
    <div
      className={classes.sliderContainer}
      ref={containerRef}
      data-test-locator={dataTestId}
    >
      <NoSsr fallback={<ListingsSliderSkeleton />}>
        <LoadableSwiperSlider
          withKeyboardControls
          items={sliderItems}
          slidesPerView={sliderPerView}
          spaceBetween={spaceBetween}
          controls={
            isLargerThanMd && shouldShowControls
              ? [
                  ListingsSliderControlPrevious as unknown as React.ReactElement,
                  ListingsSliderControlNext as unknown as React.ReactElement,
                ]
              : undefined
          }
        />
      </NoSsr>
    </div>
  );
};

ListingsSlider.CardWithButton = ListingsSliderCardWithButton;
