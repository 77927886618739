import {
  ListingCostsCosts,
  ListingCostKey,
  ListingCostPayableByValues,
} from '@ha/api/v2/types/Listing';
import { UtilityBills, UtilityBillsValues } from '../Listing/types';

const UTILITY_COST_KEYS: ListingCostKey[] = [
  'water-bill',
  'gas-bill',
  'electricity-bill',
  'internet-bill',
];

export function getUtilityBillsFromCosts(
  costs: ListingCostsCosts,
): UtilityBills {
  let costsIncluded = 0;
  let costsExcluded = 0;
  let costsNotApplicable = 0;
  let costsNotSpecified = 0;

  UTILITY_COST_KEYS.forEach(key => {
    const cost = costs[key];

    if (!cost) {
      costsNotSpecified += 1;

      return;
    }

    if (cost.payableBy === ListingCostPayableByValues.INCLUDED_IN_RENT) {
      costsIncluded += 1;
    }

    if (cost.payableBy === ListingCostPayableByValues.NOT_APPLICABLE) {
      costsNotApplicable += 1;
    }

    if (cost.payableBy === ListingCostPayableByValues.TENANT) {
      costsExcluded += 1;
    }
  });

  if (
    costsIncluded === 4 ||
    (costsIncluded > 0 && costsIncluded + costsNotApplicable === 4)
  ) {
    return UtilityBillsValues.INCLUDED;
  }

  if (costsExcluded + costsNotApplicable + costsNotSpecified === 4) {
    return UtilityBillsValues.EXCLUDED;
  }

  return UtilityBillsValues.PARTIALLY;
}
