import { getIsNorthAmericanCountryName } from './isNorthAmerican';

export const showAdvertiserReviewsSummary = (
  totalReviews: number,
  avgRating: number,
  country?: string,
) => {
  const isNorthAmericanAdvertiser = getIsNorthAmericanCountryName(country);
  return (totalReviews >= 3 || avgRating >= 3) && !isNorthAmericanAdvertiser;
};
