import React from 'react';
import { Typography } from '@hbf/dsl/legacy';

export const Label: React.FC<React.PropsWithChildren> = ({
  children,
  ...props
}) => (
  <Typography.H3 color="inherit" noWrap {...props}>
    {children}
  </Typography.H3>
);
