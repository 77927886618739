import { defineKey } from '@ha/intl';

import { AmenitiesValue } from 'ha/types/SearchFilters';

export const SEARCH_FILTER_AMENITIES = [
  AmenitiesValue.DISHWASHER,
  AmenitiesValue.WASHING_MACHINE,
  AmenitiesValue.DRYER,
  AmenitiesValue.AIR_CONDITIONING,
  AmenitiesValue.HEATING,
] as const;

export const SEARCH_FILTER_AMENITIES_TRANSLATIONS = {
  [AmenitiesValue.DISHWASHER]: defineKey(
    'search.filters.popup.label.dishwasher',
  ),
  [AmenitiesValue.WASHING_MACHINE]: defineKey(
    'search.filters.popup.label.washing_machine',
  ),
  [AmenitiesValue.DRYER]: defineKey('search.filters.popup.label.dryer'),
  [AmenitiesValue.AIR_CONDITIONING]: defineKey(
    'search.filters.popup.label.air_conditioning',
  ),
  [AmenitiesValue.HEATING]: defineKey('search.filters.popup.label.heating'),
};
