import React from 'react';
import { makeStyles } from 'tss-react/mui';

type ReactDivProps = React.PropsWithChildren<
  React.HTMLAttributes<HTMLDivElement>
>;

const useStyles = makeStyles()(theme => ({
  layout: {
    padding: theme.spacing(8, 5),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(8),
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(9),
    },
  },
  container: {
    // We need a wrapper for the container
    // Hardcoded for now
    maxWidth: '1280px',
    margin: 'auto',
  },
}));

export const Container = ({ children, className, ...props }: ReactDivProps) => {
  const { classes, cx } = useStyles();
  return (
    <div className={cx(classes.container, className)} {...props}>
      {children}
    </div>
  );
};

export const SectionLayout = ({
  children,
  className,
  ...props
}: ReactDivProps) => {
  const { classes, cx } = useStyles();
  return (
    <section className={cx(classes.layout, className)} {...props}>
      {children}
    </section>
  );
};

SectionLayout.Container = Container;
