import { defineKey } from '@ha/intl';

import { ContractTypeValue } from 'ha/types/SearchFilters';

export const SEARCH_FILTER_CONTRACT_TYPE = [
  ContractTypeValue.Monthly,
  ContractTypeValue.Daily,
  ContractTypeValue.Fortnightly,
] as const;

export const SEARCH_FILTER_CONTRACT_TYPE_TRANSLATIONS = {
  [ContractTypeValue.Monthly]: defineKey('search.filters.popup.label.monthly'),
  [ContractTypeValue.Daily]: defineKey('search.filters.popup.label.daily'),
  [ContractTypeValue.Fortnightly]: defineKey(
    'search.filters.popup.label.every_two_weeks',
  ),
};
