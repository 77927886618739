import React from 'react';

import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/system';

import { animateScroll, scroller } from 'react-scroll';
import { makeStyles } from 'tss-react/mui';

import { Typography, Button } from '@hbf/dsl/core';

import { useIntl } from 'ha/i18n';

import { CurveSVG } from 'ha/components/CurveSVG';
import { SmartTrack as Track } from 'ha/components/track/SmartTrack';
import { usePage } from 'ha/modules/Page/contexts/PageContext';

const useStyles = makeStyles()(theme => ({
  container: {
    marginBottom: theme.spacing(8),
    paddingInline: theme.spacing(8),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(9),
    },
    display: 'flex',
    gap: theme.spacing(8),
    alignItems: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    background: theme.palette.neutral[100],
  },
  svgCurve: {
    fill: theme.palette.neutral[100],
  },
  svgWrapper: {
    backgroundColor: theme.palette.secondary.main,
  },
  wrapper: {
    marginTop: '-1px',
  },
  title: {
    color: theme.palette.secondary.main,
  },
}));

export const FindYourHomeSection = ({
  setSearchInputFocus,
}: {
  setSearchInputFocus: () => void;
}) => {
  const { classes } = useStyles();
  const { T } = useIntl();
  const { category } = usePage();

  const theme = useTheme();
  const isLargerThanLG = useMediaQuery(theme.breakpoints.up('lg'));
  const isLargerThanMd = useMediaQuery(theme.breakpoints.up('md'));

  const onButtonClick = React.useCallback(() => {
    if (isLargerThanLG) {
      animateScroll.scrollToTop();
    } else {
      scroller.scrollTo('homepage.searchField', {
        duration: 800,
        offset: -30,
        smooth: true,
      });
    }
    setSearchInputFocus();
  }, [isLargerThanLG, setSearchInputFocus]);

  return (
    <div className={classes.wrapper}>
      <div className={classes.svgWrapper}>
        <CurveSVG className={classes.svgCurve} />
      </div>
      <div className={classes.container}>
        <div>
          <Typography
            variant={
              isLargerThanMd ? 'display/desktop/lg' : 'display/mobile/lg'
            }
            className={classes.title}
          >
            {T('home.bottom_banner.title')}
          </Typography>
        </div>
        <div>
          <Track name="Clicked Find your next Home" pageCategory={category}>
            <Button
              variant="contained"
              size="large"
              color="primary"
              onClick={onButtonClick}
            >
              {T('home.bottom_banner.cta_find_home')}
            </Button>
          </Track>
        </div>
      </div>
    </div>
  );
};
