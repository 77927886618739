import React from 'react';

import { haGroteskFont } from 'ha/constants/fonts/dslFonts';
import { HOME } from 'ha/constants/pageNames';

import { Page } from 'ha/modules/Page';
import { RebrandThemeProvider } from 'ha/modules/ThemeProvider/RebrandThemeProvider';

import { HomeLayout } from './components/HomeLayout';

const HomePage: React.FC = () => {
  return (
    <Page
      category={HOME}
      name={HOME}
      activeFonts={[haGroteskFont]}
      // Set to true since search bar still needs legacy fonts
      // TODO: update when search bar for home is redesigned
      useLegacyFonts
      remarketingTag={{
        hrental_id: '',
        hrental_pagetype: 'homepage',
        hrental_startdate: '',
        hrental_enddate: '',
        hrental_totalvalue: '',
      }}
    >
      <RebrandThemeProvider>
        <HomeLayout />
      </RebrandThemeProvider>
    </Page>
  );
};

export { HomePage };
