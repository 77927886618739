import React from 'react';

import { useMediaQuery, useTheme } from '@mui/material';

import { makeStyles } from 'tss-react/mui';

import { Typography, units } from '@hbf/dsl/core';
import { Grid } from '@hbf/dsl/legacy';

import { useConfig } from 'ha/helpers/config';

import { Imgix } from 'ha/modules/Image/Imgix';

import { HOME_IMGIX_PATH, TRANSITION_SPEED } from '../../constants';

export type UPSItemData = {
  benefit: string;
  description: string;
  title: string;
};

const useStyles = makeStyles()(theme => ({
  active: {
    opacity: 1,
  },
  container: {
    transition: `background-color ${TRANSITION_SPEED}ms linear`,
    [theme.breakpoints.up('md')]: {
      flexWrap: 'nowrap',
      columnGap: theme.utils.spacing('ref/spacing/10'),
    },
  },
  containerDark: {
    backgroundColor: theme.palette.secondary.main,
  },
  containerLight: {
    backgroundColor: theme.palette.mono.light,
  },
  image: {
    userSelect: 'none',
    width: units.pxToRem(220),
    maxWidth: '100%',
    [theme.breakpoints.up('md')]: {
      width: units.pxToRem(400),
    },
  },
  upsImage: {
    opacity: 0,
    transition: `opacity ${TRANSITION_SPEED}ms linear`,
  },
  upsContent: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.utils.spacing('ref/spacing/4'),
    opacity: 0.2,
    transition: `opacity ${TRANSITION_SPEED}ms linear`,
    [theme.breakpoints.up('md')]: {
      rowGap: theme.utils.spacing('ref/spacing/5'),
      paddingBottom: theme.utils.spacing('ref/spacing/20'),
    },
  },
  upsContentDark: {
    color: theme.palette.neutral[100],
  },
  upsContentLight: {
    color: theme.palette.secondary.main,
  },
}));

interface UPSSectionItemProps {
  benefit: string;
  description: string;
  imgName: string;
  isActive?: boolean;
  isBackgroundDark: boolean;
  title: string;
}

export const USPSectionItem = React.forwardRef<
  HTMLDivElement,
  UPSSectionItemProps
>(
  (
    { benefit, description, imgName, isActive, isBackgroundDark, title },
    ref,
  ) => {
    const { classes, cx } = useStyles();

    const { imgix } = useConfig();
    const imgSrc = `${imgix.sourceURL}/${HOME_IMGIX_PATH}/${imgName}.png`;

    const theme = useTheme();
    const isLargerThanMd = useMediaQuery(theme.breakpoints.up('md'));

    return (
      <Grid
        container
        className={cx(
          classes.container,
          isBackgroundDark ? classes.containerDark : classes.containerLight,
        )}
        ref={ref}
      >
        <Grid item xs={12} lg={4}>
          <div
            className={cx(classes.upsImage, {
              [classes.active]: isActive,
            })}
          >
            <Imgix
              src={imgSrc}
              className={classes.image}
              width={isLargerThanMd ? 360 : 200}
              htmlAttributes={{ alt: title, loading: 'lazy', ar: '10:9' }}
            />
          </div>
        </Grid>
        <Grid item xs={12} lg={8}>
          <div
            className={cx(
              classes.upsContent,
              isBackgroundDark
                ? classes.upsContentDark
                : classes.upsContentLight,
              {
                [classes.active]: isActive,
              },
            )}
          >
            <Typography
              variant={
                isLargerThanMd ? 'heading/desktop/h2' : 'heading/mobile/h2'
              }
            >
              {title}
            </Typography>
            <Typography variant="body/lg-regular">{description}</Typography>
            <Typography variant="body/lg-regular">{benefit}</Typography>
          </div>
        </Grid>
      </Grid>
    );
  },
);
