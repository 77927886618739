import { CurrencyFormat } from '@ha/intl';

import { useIntl } from 'ha/i18n';
import { NormalizedAlgoliaListing } from 'ha/models/Listing/types';

import { getListingTypeTitle } from './helpers/getListingTypeTitle';

export const useListingMetaTitle = ({
  city,
  street,
  price,
  currency,
  propertyType,
}: NormalizedAlgoliaListing) => {
  const { T, formatCurrency } = useIntl();
  const listingTypeLabel = getListingTypeTitle(T, propertyType);
  const formattedPrice = formatCurrency(
    price,
    currency,
    CurrencyFormat.WITH_CURRENCY_SYMBOL_NO_FRACTION,
  );

  const place = `${city}, ${street}`;

  return T('listing.meta_title', listingTypeLabel, formattedPrice, place);
};
