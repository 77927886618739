import React from 'react';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  card: { width: '100%', height: '100%' },
}));

type CardProps = React.HtmlHTMLAttributes<HTMLDivElement>;

export const Card = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<CardProps>
>(({ children, className, ...rest }, ref) => {
  const { classes, cx } = useStyles();
  return (
    <div ref={ref} {...rest} className={cx(classes.card, className)}>
      {children}
    </div>
  );
});
