import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { Tab, TabProps, units } from '@hbf/dsl/legacy';

type TabVariants = 'rounded' | 'squared';

export const useStyles = makeStyles<{ variant: TabVariants }>()(
  (t, { variant }) => ({
    root: {
      zIndex: 1,
      maxWidth: 'initial',
      textTransform: 'none',
      minWidth: units.rem(9),
      [t.breakpoints.up('md')]: {
        minWidth: units.rem(15),
      },
    },
    selected: {
      background: t.palette.white.main,
      ...(variant === 'rounded' ? { borderRadius: 5000 } : {}),
      color: t.palette.primary.main,
    },
  }),
);

export const StyledTab = React.forwardRef<
  HTMLDivElement,
  TabProps & { variant: 'rounded' | 'squared' }
>(({ classes, variant, ...otherProps }, ref) => {
  const { classes: ownClasses, cx } = useStyles({ variant });

  return (
    <Tab
      ref={ref}
      disableRipple
      {...otherProps}
      classes={{
        ...ownClasses,
        ...classes,
        root: cx(ownClasses.root, classes?.root),
        selected: cx(ownClasses.selected, classes?.selected),
      }}
    />
  );
});
