import React from 'react';

import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/system';

import { makeStyles } from 'tss-react/mui';

import { useScrollObserver } from 'ha/helpers/hooks/useScrollObserver';
import { useIntl } from 'ha/i18n';

import { TRANSITION_SPEED } from '../../constants';
import { SectionLayout } from '../../layouts';
import { UPSItemIds } from '../../types';

import { USPSectionItem } from './USPSectionItem';

const useStyles = makeStyles()(theme => ({
  container: {
    transition: `background-color ${TRANSITION_SPEED}ms linear`,
  },
  containerDarkColor: {
    backgroundColor: theme.palette.secondary.main,
  },
  containerLightColor: {
    backgroundColor: theme.palette.mono.light,
  },
  uspList: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.utils.spacing('ref/spacing/10'),
    [theme.breakpoints.up('md')]: {
      rowGap: theme.utils.spacing('ref/spacing/0'),
    },
  },
}));

type ElementsDistance = {
  id: UPSItemIds;
  elementDistance: number;
};

export const USPSection = () => {
  const { T } = useIntl();
  const { classes, cx } = useStyles();
  const theme = useTheme();
  const isLargerThanMd = useMediaQuery(theme.breakpoints.up('md'));
  const [activeId, setActiveId] = React.useState<number>();

  const { createEventListener, isSectionVisible, sectionRef } =
    useScrollObserver();
  const elementOneRef = React.useRef<HTMLDivElement>(null);
  const elementTwoRef = React.useRef<HTMLDivElement>(null);
  const elementThreeRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const elementOne = elementOneRef.current;
    const elementTwo = elementTwoRef.current;
    const elementThree = elementThreeRef.current;

    if (!elementOne || !elementTwo || !elementThree || !isSectionVisible) {
      return undefined;
    }

    const getElementDistance = (element: HTMLDivElement) => {
      const middleOfViewport = window.innerHeight / 2;
      const { top, height } = element.getBoundingClientRect();
      const middleOfTheElement = top + height / 2;
      return Math.abs(middleOfTheElement - middleOfViewport);
    };

    const getClosestElement = () => {
      const elements: ElementsDistance[] = [
        {
          id: 0,
          elementDistance: getElementDistance(elementOne),
        },
        {
          id: 1,
          elementDistance: getElementDistance(elementTwo),
        },
        {
          id: 2,
          elementDistance: getElementDistance(elementThree),
        },
      ];

      const sorted = elements.sort(
        (pre, next) => pre.elementDistance - next.elementDistance,
      );

      setActiveId(sorted[0].id);
    };

    getClosestElement();

    const { removeEventListener } = createEventListener(getClosestElement);

    return () => removeEventListener();
  }, [createEventListener, isLargerThanMd, isSectionVisible]);

  return (
    <div ref={sectionRef}>
      <SectionLayout
        className={cx(
          classes.container,
          activeId && activeId % 2 === 1
            ? classes.containerDarkColor
            : classes.containerLightColor,
        )}
      >
        <SectionLayout.Container>
          <div className={classes.uspList}>
            <USPSectionItem
              benefit={T('home.safe_payments.benefit')}
              description={T('home.safe_payments.description')}
              isActive={activeId === 0 || !isLargerThanMd}
              isBackgroundDark={!!(activeId && activeId % 2 === 1)}
              imgName="ups-1"
              title={T('home.safe_payments.title')}
              ref={elementOneRef}
            />
            <USPSectionItem
              benefit={T('home.no_viewings.benefit')}
              description={T('home.no_viewings.description')}
              isActive={activeId === 1 || !isLargerThanMd}
              isBackgroundDark={!!(activeId && activeId % 2 === 1)}
              imgName="ups-2"
              title={T('home.no_viewings.title')}
              ref={elementTwoRef}
            />
            <USPSectionItem
              benefit={T('home.direct_chat.benefit')}
              description={T('home.direct_chat.description')}
              isActive={activeId === 2 || !isLargerThanMd}
              isBackgroundDark={!!(activeId && activeId % 2 === 1)}
              imgName="ups-3"
              title={T('home.direct_chat.title')}
              ref={elementThreeRef}
            />
          </div>
        </SectionLayout.Container>
      </SectionLayout>
    </div>
  );
};
