import React from 'react';

import { Helmet } from 'react-helmet-async';
import { useIntl } from 'ha/i18n';
import { useImgix } from 'ha/modules/Image/useImgix';
import { useConfig } from 'ha/helpers/config';

export const Organization: React.FC = () => {
  const { urlResolver } = useIntl();

  const { transformImageURL } = useImgix();

  const { imgix } = useConfig();
  const imgSrc = `${imgix.sourceURL}/static/logo-840x240.png`;

  return (
    <Helmet>
      <script type="application/ld+json">
        {`{
            "@context": "http://schema.org",
            "@type": "Organization",
            "name": "HousingAnywhere.com",
            "legalName": "HousingAnywhere B.V.",
            "url": "${urlResolver.absolutizePath(`/`)}",
            "logo": "${transformImageURL(imgSrc)}",
            "foundingDate": "2009",
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "Blaak 555",
              "addressLocality": "Rotterdam",
              "postalCode": "3011 GB",
              "addressCountry": "NLD"
            },
            "contactPoint": {
              "@type": "ContactPoint",
              "contactType": "customer support",
              "telephone": "[+31 85 888 1399]",
              "email": "support@housinganywhere.com"
            },
            "sameAs": [
              "${urlResolver.getFacebookUrl()}",
              "${urlResolver.getTwitterUrl()}",
              "${urlResolver.getYoutubeUrl()}",
              "${urlResolver.getLinkedinUrl()}"
            ]
          }
        `}
      </script>
    </Helmet>
  );
};
