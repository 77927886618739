import React from 'react';
import { makeStyles } from 'tss-react/mui';

import { ImgixSize } from 'ha/modules/Image/constants';
import { useMediaQuery } from 'ha/helpers/MediaQuery';
import { ContentfulImg } from 'ha/modules/Contentful/components/ContentfulImg';

const useStyles = makeStyles()(() => ({
  image: {
    width: '100%',
    height: '100%',
    borderRadius: '100%',
    userSelect: 'none',
  },
}));

interface TestimonialsSectionUserAvatarProps {
  avatar: string;
}

export const TestimonialsSectionUserAvatar: React.FC<
  TestimonialsSectionUserAvatarProps
> = ({ avatar }) => {
  const { classes } = useStyles();
  const { md } = useMediaQuery();

  const thumbnailSize = md
    ? ImgixSize.thumbnailXLarge
    : ImgixSize.thumbnailMedium;

  return (
    <ContentfulImg
      src={avatar}
      widths={[thumbnailSize.w]}
      className={classes.image}
      loading="lazy"
    />
  );
};
