import React from 'react';

import { makeStyles } from 'tss-react/mui';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/system';
import { useSelector } from 'react-redux';
import { Element as ScrollElement } from 'react-scroll';

import { ChevronDown, ChevronUp } from '@hbf/icons/brand-bold';
import { Grid } from '@hbf/dsl/legacy';
import { Typography, Link } from '@hbf/dsl/core';

import { City } from '@ha/api/v2/types';
import { isContentfulSupported } from '@ha/contentful';

import { FeaturedCountries } from 'ha/constants/FeaturedCountries';

import { getFeaturedCities } from 'ha/modules/FeaturedCities/selectors';
import { useIntl } from 'ha/i18n';

import { SectionLayout } from '../../layouts';

const useStyles = makeStyles()(theme => ({
  container: {
    padding: theme.spacing(0, 5),
  },
  citiesList: {
    [theme.breakpoints.up('sm')]: {
      height: '920px',
    },
    [theme.breakpoints.up('md')]: {
      height: '510px',
    },
  },
  arrowIcon: {
    height: theme.spacing(4),
  },
}));

export const FeaturedCities = () => {
  const { classes } = useStyles();
  const [showAll, setShowAll] = React.useState(false);
  const { T, urlResolver, lang } = useIntl();

  const theme = useTheme();
  const isLargerThanSm = useMediaQuery(theme.breakpoints.up('sm'));

  const featuredCities = useSelector(getFeaturedCities);

  const countriesArray: [string, City[]][] = Object.entries(
    featuredCities.reduce(
      (acc, city) => ({
        ...acc,
        [city.countryName]: [...(acc[city.countryName] || []), city],
      }),
      {},
    ),
  );
  countriesArray.sort();

  const getCountryUrl = (city: City) => {
    let countryNameForUrl = isContentfulSupported(lang)
      ? city.countryName
      : FeaturedCountries.find(fc => fc.code === city.countryCode.toLowerCase())
          ?.name;

    if (lang === 'de' && city.countryCode === 'BE') {
      // replace non-existent /de/Königreich-Belgien with the correct url
      countryNameForUrl = 'Belgien';
    }
    if (lang === 'fr' && city.countryCode === 'CZ') {
      // replace non-existent /fr/Tchéquie with the correct url
      countryNameForUrl = 'République-tchèque';
    }
    if (lang === 'de' && city.countryCode === 'CZ') {
      // replace non-existent /de/Tschechien with the correct url
      countryNameForUrl = 'Tschechische-Republik';
    }
    if (lang === 'es' && city.countryCode === 'CZ') {
      // replace non-existent /es/Chequia with the correct url
      countryNameForUrl = 'República-Checa';
    }

    const countryUrl = countryNameForUrl
      ? urlResolver.getCountryUrl(countryNameForUrl.replace(/ /g, '-'))
      : '/';

    return countryUrl;
  };

  return (
    <ScrollElement name="homepage.top.cities">
      <SectionLayout className={classes.container}>
        <SectionLayout.Container>
          <Grid
            spacing={6}
            className={classes.citiesList}
            container
            direction="column"
            wrap="wrap"
          >
            {countriesArray.map(([country, citiesInfo], index) => {
              if (!isLargerThanSm && index > 3 && !showAll) return null;
              const countryUrl = getCountryUrl(citiesInfo[0]);
              return (
                <Grid
                  item
                  container
                  xs="auto"
                  direction="column"
                  spacing={2}
                  key={country}
                >
                  <Grid item xs="auto">
                    {countryUrl ? (
                      <Link href={countryUrl} size="md" title={country}>
                        {country}
                      </Link>
                    ) : (
                      <Typography variant="body/sm-semibold">
                        {country}
                      </Typography>
                    )}
                  </Grid>

                  {citiesInfo.map(city => (
                    <Grid item xs="auto" key={city.cityName}>
                      <Link
                        href={city.searchUrl}
                        size="sm"
                        title={`${city.cityName}, ${country}`}
                      >
                        {city.cityName}
                      </Link>
                    </Grid>
                  ))}
                </Grid>
              );
            })}

            {!isLargerThanSm && (
              <Grid item display="flex" alignItems="center">
                <Link
                  underline="dotted"
                  size="md"
                  onClick={() => setShowAll(!showAll)}
                  endIcon={
                    showAll ? (
                      <ChevronUp className={classes.arrowIcon} />
                    ) : (
                      <ChevronDown className={classes.arrowIcon} />
                    )
                  }
                >
                  {showAll ? T('cta.show_less') : T('cta.show_all')}
                </Link>
              </Grid>
            )}
          </Grid>
        </SectionLayout.Container>
      </SectionLayout>
    </ScrollElement>
  );
};
