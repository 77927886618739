import React from 'react';

import { Helmet } from 'react-helmet-async';
import { useTitle } from 'ha/helpers/hooks/useTitle';
import { Language } from '@ha/intl';
import { useIntl } from 'ha/i18n';
import { Titles } from '../constants';

export const HomeHelmet: React.FC = () => {
  const { urlResolver, lang } = useIntl();
  const title = useTitle(Titles[lang] || Titles.en);

  return (
    <Helmet>
      <title key="title">{title}</title>
      <link
        key="link canonical"
        rel="canonical"
        href={urlResolver.absolutizePath('/', { lang })}
      />
      <link
        key="link alternate x-default"
        rel="alternate"
        hrefLang="x-default"
        href={urlResolver.absolutizePath('/', { skipAddLanguage: true })}
      />
      {Object.keys(Language).map((_lang: Language) => (
        <link
          key={`link alternate ${_lang}`}
          rel="alternate"
          hrefLang={_lang}
          href={urlResolver.absolutizePath('/', { lang: _lang })}
        />
      ))}
    </Helmet>
  );
};
