import React from 'react';
import { makeStyles } from 'tss-react/mui';

import { units } from '@hbf/dsl/core';
import { useConfig } from 'ha/helpers/config';
import { Imgix } from 'ha/modules/Image/Imgix';
import { useMediaQuery } from 'ha/helpers/MediaQuery';
import { ImgixSize } from 'ha/modules/Image/constants';

import { HOME_IMGIX_PATH } from '../../constants';

const useStyles = makeStyles()(theme => ({
  container: {
    textAlign: 'center',
  },
  image: {
    marginBottom: units.pxToRem(-25),
    width: units.rem(20),

    [theme.breakpoints.up('sm')]: {
      marginBottom: units.pxToRem(-32),
      width: units.rem(25),
    },

    [theme.breakpoints.up('md')]: {
      marginBottom: units.pxToRem(-50),
      width: units.rem(30),
    },

    maxWidth: '100%',
  },
}));

export const HeroSectionIntroImage = () => {
  const { classes } = useStyles();
  const { md } = useMediaQuery();
  const { imgix } = useConfig();
  const imgSrc = `${imgix.sourceURL}/${HOME_IMGIX_PATH}/hero-section.png`;

  return (
    <div className={classes.container}>
      <Imgix
        src={imgSrc}
        className={classes.image}
        width={md ? ImgixSize.small.w : ImgixSize.xxsmall.w}
        imgixParams={{ ar: '4:3' }}
        htmlAttributes={{ alt: 'Move in' }}
      />
    </div>
  );
};
