import React from 'react';
import { makeStyles } from 'tss-react/mui';

import { useIntl } from 'ha/i18n';
import { Divider, Grid } from '@hbf/dsl/legacy';
import { useMediaQuery, useTheme } from '@mui/material';
import { Typography, Link } from '@hbf/dsl/core';
import { SmartTrack as Track } from 'ha/components/track/SmartTrack';
import { usePage } from 'ha/modules/Page/contexts/PageContext';
import { SectionLayout } from '../../layouts';
import { FAQSectionItem } from './FAQSectionItem';

const useStyles = makeStyles()(theme => ({
  container: {
    backgroundColor: theme.palette.primary.main,
  },
  title: {
    color: theme.palette.secondary.main,
  },
  accordionIcon: {
    color: theme.palette.secondary.main,
  },

  linkButton: {
    borderColor: 'currentColor',
  },
}));

export const FAQSection = () => {
  const { T, urlResolver } = useIntl();
  const { classes } = useStyles();
  const { category } = usePage();

  const theme = useTheme();

  const isLargerThanMd = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <SectionLayout>
      <SectionLayout.Container>
        <Grid container alignItems="center" justifyContent="center">
          <Grid
            item
            xs={12}
            md={8}
            display="flex"
            flexDirection="column"
            gap={8}
          >
            <Typography
              variant={
                isLargerThanMd ? 'heading/desktop/h2' : 'heading/mobile/h2'
              }
              className={classes.title}
            >
              {T('home.faq.title')}
            </Typography>
            <div>
              <Divider />
              <FAQSectionItem>
                <FAQSectionItem.Question>
                  {T('home.faq.question_agency')}
                </FAQSectionItem.Question>
                <FAQSectionItem.Answer>
                  {T('home.faq.answer_agency')}
                </FAQSectionItem.Answer>
              </FAQSectionItem>
              <Divider />
              <FAQSectionItem>
                <FAQSectionItem.Question>
                  {T('home.faq.question_how_to_rent')}
                </FAQSectionItem.Question>
                <FAQSectionItem.Answer>
                  {T('home.faq.answer_how_to_rent')}
                </FAQSectionItem.Answer>
              </FAQSectionItem>
              <Divider />
              <FAQSectionItem>
                <FAQSectionItem.Question>
                  {T('home.faq.question_cancel_booking')}
                </FAQSectionItem.Question>
                <FAQSectionItem.Answer>
                  {T('home.faq.answer_cancel_booking')}
                </FAQSectionItem.Answer>
              </FAQSectionItem>
              <Divider />
              <FAQSectionItem>
                <FAQSectionItem.Question>
                  {T('home.faq.question_viewing')}
                </FAQSectionItem.Question>
                <FAQSectionItem.Answer>
                  {T('home.faq.answer_viewing')}
                </FAQSectionItem.Answer>
              </FAQSectionItem>
              <Divider />
            </div>

            <Grid display="flex" alignItems="flex-start">
              <Track name="Clicked FAQ on Homepage" pageCategory={category}>
                <Link
                  target="_blank"
                  href={urlResolver.getAnswersUrl()}
                  size="lg"
                  underline="always"
                  className={classes.title}
                >
                  {T('home.faq.cta_help_center')}
                </Link>
              </Track>
            </Grid>
          </Grid>
        </Grid>
      </SectionLayout.Container>
    </SectionLayout>
  );
};
