import React from 'react';

import { makeStyles } from 'tss-react/mui';

import { Button, ButtonProps } from '@hbf/dsl/core';

import { useIntl } from 'ha/i18n';

const useStyles = makeStyles()(theme => ({
  root: {
    position: 'relative',
    height: '100%',
    backgroundColor: theme.palette.mono.light,
  },
  button: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
}));

export const ListingsSliderCardWithButton = ({
  className,
  children,
  ...rest
}: ButtonProps) => {
  const { T } = useIntl();
  const { classes, cx } = useStyles();

  return (
    <div className={classes.root}>
      <Button
        className={cx(classes.button, className)}
        variant="outlined"
        color="neutral"
        {...rest}
      >
        {children ?? T('View more')}
      </Button>
    </div>
  );
};
