import React from 'react';

import { UnitType } from '@ha/api/v2/types/inventory/UnitType';

import { HOME } from 'ha/constants/pageNames';

import { normalizeApiV2Listing } from 'ha/models/Listing/normalizeApiV2Listing';
import { useTrackEvent } from 'ha/modules/Analytics/helpers/TrackEvent';

import { ListingCard } from 'ha/components/Redesign/ListingCard';
import { useRecentlyViewedListings } from 'ha/modules/RecentlyViewed';

import { ListingsSlider } from '../../components/ListingsSlider';

export const RecentlyViewedListingsSlider = () => {
  const trackEvent = useTrackEvent();
  const { getRecentlyViewedListings } = useRecentlyViewedListings();
  const [recentlyViewedListings, setRecentlyViewedListings] = React.useState<
    UnitType[]
  >([]);

  const fetchListings = React.useCallback(async () => {
    const listings = await getRecentlyViewedListings();
    setRecentlyViewedListings(listings);
  }, [getRecentlyViewedListings]);

  React.useEffect(() => {
    fetchListings();
  }, [fetchListings]);

  const handleCardClick = React.useCallback(() => {
    trackEvent('Listing Card Clicked', {
      page: HOME,
      component: 'Recently Viewed',
    });
  }, [trackEvent]);

  const getSliderItems = () => {
    return recentlyViewedListings.map(listing => ({
      key: listing.id,
      element: (
        <ListingCard
          key={listing.id}
          room={normalizeApiV2Listing(listing)}
          withFavorites
          onCardClick={handleCardClick}
        />
      ),
    }));
  };

  return (
    <ListingsSlider
      sliderItems={getSliderItems()}
      data-test-locator="RecentlyViewedListingsSlider"
    />
  );
};
