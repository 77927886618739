import { UtilityBills, UtilityBillsValues } from 'ha/models/Listing/types';
import { defineKey as T } from '@ha/intl';

export enum ListingDepositType {
  NO_DEPOSIT,
  DEPOSIT_REQUIRED,
  DEPOSIT_OPTIONAL,
}

export const DEPOSIT_TYPE_TRANSLATIONS: Record<ListingDepositType, string> = {
  [ListingDepositType.NO_DEPOSIT]: T('ldp.no_deposit'),
  [ListingDepositType.DEPOSIT_REQUIRED]: T('ldp.deposit_required'),
  [ListingDepositType.DEPOSIT_OPTIONAL]: T('ldp.deposit_optional'),
};

export const BILLS_INFO_EVENT_DATA: Record<UtilityBills, string> = {
  [UtilityBillsValues.INCLUDED]: 'Included',
  [UtilityBillsValues.EXCLUDED]: T('Excluded'),
  [UtilityBillsValues.PARTIALLY]: T('Partially Included'),
};
