import { Thunk } from 'ha/myredux/types';
import { GlobalState } from 'ha/types/store';
import { getCurrentLanguageCode } from 'ha/modules/LanguageSwitcher/selectors';
import { getIsLoaded, getIsLoading } from '../selectors';

import { Actions } from './actions';

type Load = () => Thunk<GlobalState>;

export const load: Load = () => (dispatch, getState, services) => {
  const isLoaded = getIsLoaded(getState());
  const isLoading = getIsLoading(getState());

  if (isLoaded || isLoading) {
    return Promise.resolve();
  }

  dispatch(Actions.start());

  const lang = getCurrentLanguageCode(getState());

  return services.apiV2
    .getFeaturedCities({ lang })
    .then(({ data: { cities } }) => {
      dispatch(Actions.done(cities));
    })
    .catch(error => {
      dispatch(Actions.fail(error));
    });
};
