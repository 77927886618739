import React from 'react';

interface TabPanelProps {
  currentTabIndex: number;
  index: number;
  dataTestLocator?: string;
}

export const TabPanel: React.FC<React.PropsWithChildren<TabPanelProps>> = ({
  children,
  currentTabIndex,
  index,
  dataTestLocator = '',
}) => (
  <div data-test-locator={dataTestLocator} hidden={currentTabIndex !== index}>
    {children}
  </div>
);
