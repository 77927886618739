import { ImageOptions } from 'ha/modules/Contentful/types';
import { buildParams } from '@ha/search-query';

export function buildContentfulAssetUrl(
  url: string,
  params: ImageOptions = {},
) {
  if (
    !url ||
    (!url.includes('images.contentful.com') &&
      !url.includes('images.ctfassets.net')) ||
    url.includes('.svg')
  ) {
    return url;
  }

  const format = params.format || 'jpg';

  const query = buildParams({
    fm: format,
    fl: format === 'jpg' ? 'progressive' : null,
    q: format !== 'png' && params.quality ? params.quality : null,
    w: params.width,
    h: params.height,
    fit: params.fit,
  });

  return `${url}${query}`;
}
