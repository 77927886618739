import { DateTimeFormat, DateTimeFormatFunc, TranslateFunc } from '@ha/intl';
import { firstAvailableDay as getFirstAvailableDay } from 'ha/utils/exclusionDate/firstAvailableDay';
import { NormalizedAlgoliaListing } from 'ha/models/Listing/types';
import dayjs from 'dayjs';

export const getAvailableDateLabel = (
  T: TranslateFunc,
  formatDateTime: DateTimeFormatFunc,
  room: NormalizedAlgoliaListing,
) => {
  const { exclusions, minimalRentalPeriod } = room;
  const firstAvailableDate = getFirstAvailableDay(
    exclusions,
    minimalRentalPeriod,
  );

  if (!firstAvailableDate) return undefined;

  const isCurrentYear = dayjs(firstAvailableDate).isSame(new Date(), 'year');
  const DATE_SHORT_WITHOUT_YEAR: Intl.DateTimeFormatOptions = {
    day: 'numeric',
    month: 'short',
  };
  const dateFormat = isCurrentYear
    ? DATE_SHORT_WITHOUT_YEAR
    : DateTimeFormat.DATE_SHORT;

  const formattedDate = formatDateTime(
    new Date(firstAvailableDate),
    dateFormat,
  );

  return T('date.from_%s', formattedDate);
};
