import React from 'react';

import { makeStyles } from 'tss-react/mui';

import { CurveSVG } from 'ha/components/CurveSVG';

import { HeroSectionIntroImage } from './HeroSectionIntroImage';

const useStyles = makeStyles<{ footerSectionColor?: string }>()(
  (theme, { footerSectionColor }) => ({
    container: {
      position: 'sticky',
      bottom: 0,
      textAlign: 'center',
    },
    svgCurve: {
      fill: footerSectionColor ?? theme.palette.mono.light,
    },
  }),
);

interface Props {
  footerSectionColor?: string;
}

export const HeroSectionFooterDesktop = React.forwardRef<HTMLDivElement, Props>(
  ({ footerSectionColor }, ref) => {
    const { classes } = useStyles({ footerSectionColor });

    return (
      <div className={classes.container} ref={ref}>
        <HeroSectionIntroImage />
        <CurveSVG className={classes.svgCurve} />
      </div>
    );
  },
);
