import { defineKey } from '@ha/intl';

import { GenderValue } from 'ha/types/SearchFilters';

export const SEARCH_FILTER_GENDER = [
  GenderValue.BOYS,
  GenderValue.GIRLS,
] as const;

export const SEARCH_FILTER_GENDER_TRANSLATIONS = {
  [GenderValue.BOYS]: defineKey('search.filters.popup.label.male'),
  [GenderValue.GIRLS]: defineKey('search.filters.popup.label.female'),
} satisfies Record<(typeof SEARCH_FILTER_GENDER)[number], string>;
