import React from 'react';

import { useSelector } from 'react-redux';

import { FavoriteUnitType } from '@ha/api/v2/types/inventory/FavoriteUnitType';
import { UnitType } from '@ha/api/v2/types/inventory/UnitType';

import { HOME } from 'ha/constants/pageNames';

import { reportError } from 'ha/helpers/bugReporter/reportError';
import { useIntl } from 'ha/i18n';
import { normalizeApiV2Listing } from 'ha/models/Listing/normalizeApiV2Listing';
import { useTrackEvent } from 'ha/modules/Analytics/helpers/TrackEvent';
import { getUserId } from 'ha/modules/AuthLogic/selectors';
import { useServices } from 'ha/services';

import { ListingCard } from 'ha/components/Redesign/ListingCard';

import { ListingsSlider } from '../../components/ListingsSlider';
import { ListingsSliderSkeleton } from '../../components/ListingsSlider/ListingsSliderSkeleton';

import { FAVORITE_SLIDER_ITEMS_LIMIT } from './contants';
import { FavoriteListingsEmptyStatePlaceHolder } from './FavoriteListingsPlaceHolder';

const EVENT_LISTING_CARD_CLICKED = 'Listing Card Clicked';
const EVENT_VIEW_MORE_CLICKED = 'Clicked View More';

const extractUnitTypes = (favorites: FavoriteUnitType[]) => {
  return favorites
    .filter(
      (listing): listing is FavoriteUnitType & { unitType: UnitType } =>
        listing.unitType !== undefined,
    )
    .map(listing => ({
      ...listing.unitType,
    }));
};

const reportFetchError = (error: unknown) => {
  reportError('Unable to fetch favorite properties', {
    metaData: { error },
  });
};

interface Props {
  isActive: boolean;
}

export const FavoriteListingsSlider = ({ isActive }: Props) => {
  const { urlResolver } = useIntl();
  const trackEvent = useTrackEvent();
  const { apiV2 } = useServices();
  const userId = useSelector(getUserId)!;
  const [favoriteListings, setFavoriteListings] = React.useState<UnitType[]>(
    [],
  );
  const [isLoading, setIsLoading] = React.useState(false);

  const fetchFavoriteListings = React.useCallback(async () => {
    try {
      setIsLoading(true);
      const { data } = await apiV2.getFavoriteUnitTypes({
        query: {
          userId,
          expand: 'unit-type,photos',
          limit: FAVORITE_SLIDER_ITEMS_LIMIT,
        },
      });

      const unitTypes = extractUnitTypes(data.favorites);
      setFavoriteListings(unitTypes);
    } catch (error) {
      reportFetchError(error);
      throw error;
    } finally {
      setIsLoading(false);
    }
  }, [apiV2, userId]);

  React.useEffect(() => {
    if (isActive) {
      fetchFavoriteListings();
    }
  }, [fetchFavoriteListings, isActive]);

  const handleCardClick = React.useCallback(() => {
    trackEvent(EVENT_LISTING_CARD_CLICKED, {
      page: HOME,
      component: 'Favorites',
    });
  }, [trackEvent]);

  const handleViewMoreClick = React.useCallback(() => {
    trackEvent(EVENT_VIEW_MORE_CLICKED, {
      page: HOME,
      component: 'Favorites',
    });
  }, [trackEvent]);

  const getSliderItems = () => {
    const listings = favoriteListings.map(unitType => ({
      key: unitType.id,
      element: (
        <ListingCard
          room={normalizeApiV2Listing(unitType)}
          withGallery={false}
          withFavorites
          onCardClick={handleCardClick}
        />
      ),
    }));

    const hasMoreSlides = listings.length >= FAVORITE_SLIDER_ITEMS_LIMIT;

    if (hasMoreSlides) {
      listings.push({
        key: favoriteListings.length + 1,
        element: (
          <ListingsSlider.CardWithButton
            href={urlResolver.getFavoritesUrl()}
            onClick={handleViewMoreClick}
          />
        ),
      });
    }

    return listings;
  };

  if (isLoading) return <ListingsSliderSkeleton />;

  if (!isLoading && favoriteListings.length < 1) {
    return <FavoriteListingsEmptyStatePlaceHolder />;
  }

  return (
    <ListingsSlider
      sliderItems={getSliderItems()}
      data-test-locator="FavoriteListingsSlider"
    />
  );
};
