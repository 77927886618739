import React from 'react';

import { makeStyles } from 'tss-react/mui';

import { Fab } from '@hbf/dsl/core';
import { ArrowRight, ArrowLeft } from '@hbf/icons/brand-bold';

const useStyles = makeStyles()(theme => ({
  root: {
    position: 'absolute',
    top: '50%',
    zIndex: 2,
    boxShadow: theme.utils.elevation('elevation/4'),
  },
  icon: {
    width: theme.utils.spacing('ref/spacing/4'),
    height: theme.utils.spacing('ref/spacing/4'),
  },
  iconPrev: {
    left: theme.utils.spacing('ref/spacing/0'),
    transform: 'translate(-50%, -50%)',
  },
  iconNext: {
    right: theme.utils.spacing('ref/spacing/0'),
    transform: 'translate(50%, -50%)',
  },
}));

interface BaseProps {
  onClick?: () => void;
  disabled?: boolean;
  prev?: boolean;
  next?: boolean;
}

type Props = BaseProps &
  ({ prev: true; next?: never } | { next: true; prev?: never });

export const ListingsSliderControl: React.FC<Props> = ({
  prev,
  next,
  onClick = () => {},
  disabled = false,
}) => {
  const { classes, cx } = useStyles();

  return (
    <Fab
      onClick={onClick}
      disabled={disabled}
      color="neutral"
      size="md"
      data-test-locator={`ListingsSliderControl/${prev ? 'PREV' : 'NEXT'}`}
      className={cx(classes.root, {
        [classes.iconPrev]: prev,
        [classes.iconNext]: next,
      })}
    >
      {prev && <ArrowLeft className={classes.icon} />}
      {next && <ArrowRight className={classes.icon} />}
    </Fab>
  );
};

type ControlProps = Omit<Props, 'prev' | 'next'>;

export const ListingsSliderControlPrevious = ({
  disabled,
  onClick,
}: ControlProps) =>
  !disabled && <ListingsSliderControl prev onClick={onClick} />;

export const ListingsSliderControlNext = ({
  disabled,
  onClick,
}: ControlProps) =>
  !disabled && <ListingsSliderControl next onClick={onClick} />;
