import React from 'react';

import { makeStyles } from 'tss-react/mui';

import { HomePageData } from '@ha/contentful';
import { units, IconButton } from '@hbf/dsl/core';
import { Grid } from '@hbf/dsl/legacy';
import { ChevronPrevious, ChevronNext } from '@hbf/icons/brand-bold';

import { MaxItemsCount } from 'ha/constants/Testimonials';

import { useMediaQuery } from 'ha/helpers/MediaQuery';

import { useOptionalHermes } from 'ha/modules/Hermes';

import { SectionLayout } from '../../layouts';

import { TestimonialsSectionItem } from './TestimonialsSectionItem';
import { TestimonialsSectionUserAvatar } from './TestimonialsSectionUserAvatar';

const useStyles = makeStyles<{ sliderPosition: number }>()(
  (theme, { sliderPosition }) => ({
    wrapper: {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.neutral[100],
      overflow: 'hidden',
    },
    container: {
      paddingTop: 0,
      [theme.breakpoints.up('sm')]: {
        paddingTop: 0,
      },
      [theme.breakpoints.up('md')]: {
        paddingTop: 0,
      },
    },
    testimonials: {
      position: 'relative',
      display: 'grid',
      gridAutoRows: '1fr',
      gridTemplateColumns: 'repeat(5, 100%)',
      left: `${sliderPosition}%`,
      transition: `left 400ms cubic-bezier(0, 0, 0, 1)`,
    },
    sliderItems: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: theme.utils.spacing('ref/spacing/10'),
      gap: theme.utils.spacing('ref/spacing/3'),
      [theme.breakpoints.up('md')]: {
        gap: theme.utils.spacing('ref/spacing/4'),
        marginTop: theme.utils.spacing('ref/spacing/20'),
      },
    },
    sliderButton: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: theme.palette.neutral[100],
      // TODO: Replace it with the shape object when it's ready
      // borderRadius: theme.shape.borderRadius(3)
      borderRadius: '100%',

      transition: 'background 200ms ease-in-out',

      // TODO: remove these extra style when DSL is ready
      border: 'none',
      '&:hover': {
        background: 'none',
      },
      '&:focus': {
        background: 'none',
        outline: 'none',
      },
    },
    sliderButtonActive: {
      background: theme.palette.secondary.light,

      // TODO: remove these extra style when DSL is ready
      '&:hover': {
        background: theme.palette.secondary.light,
      },
      '&:focus': {
        background: theme.palette.secondary.light,
      },
    },
    avatar: {
      position: 'relative',
      margin: theme.spacing(1),
      border: 'none',
      width: units.pxToRem(48),
      height: units.pxToRem(48),
      backgroundColor: theme.palette.secondary.main,
      borderRadius: '100%',
      padding: 0,

      cursor: 'pointer',

      [theme.breakpoints.up('md')]: {
        width: units.pxToRem(56),
        height: units.pxToRem(56),
      },
    },
    activeAvatar: {
      '&:before': {
        position: 'absolute',
        content: '""',
        inset: units.pxToRem(-6),
        border: units.border(2, 'solid', theme.palette.neutral[100]),
        borderRadius: '100%',
      },
    },
  }),
);

export const TestimonialsSection = () => {
  const { md: isLargerThanMd } = useMediaQuery();
  const { data } = useOptionalHermes<HomePageData>();

  const displayedTestimonials =
    data?.testimonials?.slice(0, MaxItemsCount) || [];

  const [activeItemIndex, setActiveItemIndex] = React.useState(
    displayedTestimonials?.length ? 0 : -1,
  );

  const sliderPosition = 100 - (activeItemIndex + 1) * 100;

  const { classes, cx } = useStyles({ sliderPosition });

  const isFirstElement = activeItemIndex === 0;

  const isLastElement = activeItemIndex === displayedTestimonials.length - 1;

  const selectNextItem = React.useCallback(() => {
    if (isLastElement) return;
    setActiveItemIndex(index => index + 1);
  }, [isLastElement]);

  const selectPreviousItem = React.useCallback(() => {
    if (isFirstElement) return;
    setActiveItemIndex(index => index - 1);
  }, [isFirstElement]);

  if (!displayedTestimonials.length) return null;

  return (
    <div className={classes.wrapper} data-test-locator="testimonials-section">
      <div className={classes.testimonials}>
        {displayedTestimonials.map((item, index) => (
          <TestimonialsSectionItem
            key={index}
            testimonial={item}
            isVisible={index === activeItemIndex}
          />
        ))}
      </div>
      <SectionLayout className={classes.container}>
        <SectionLayout.Container>
          <Grid
            container
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid
              item
              xs={12}
              md={6}
              data-test-locator="testimonials-section-slider"
            >
              <div className={classes.sliderItems}>
                {isLargerThanMd && (
                  <IconButton
                    className={cx(classes.sliderButton, {
                      [classes.sliderButtonActive]: !isFirstElement,
                    })}
                    type="button"
                    onClick={selectPreviousItem}
                    aria-label="Previous testimonial"
                    data-test-locator="testimonials-section-slider-previous-button"
                  >
                    <ChevronPrevious fontSize="medium" />
                  </IconButton>
                )}

                {displayedTestimonials.map((item, index) => (
                  <IconButton
                    className={cx(classes.avatar, {
                      [classes.activeAvatar]: index === activeItemIndex,
                    })}
                    key={index}
                    onClick={() => setActiveItemIndex(index)}
                    type="button"
                    aria-label={`${item.name}'s testimonial`}
                    data-test-locator="testimonials-section-slider-item"
                  >
                    <TestimonialsSectionUserAvatar avatar={item.avatar} />
                  </IconButton>
                ))}

                {isLargerThanMd && (
                  <IconButton
                    className={cx(classes.sliderButton, {
                      [classes.sliderButtonActive]: !isLastElement,
                    })}
                    type="button"
                    onClick={selectNextItem}
                    size="medium"
                    aria-label="Next testimonial"
                    data-test-locator="testimonials-section-slider-next-button"
                  >
                    <ChevronNext fontSize="medium" />
                  </IconButton>
                )}
              </div>
            </Grid>
          </Grid>
        </SectionLayout.Container>
      </SectionLayout>
    </div>
  );
};
