import React from 'react';

import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/system';

import { makeStyles } from 'tss-react/mui';

import { TestimonialData } from '@ha/contentful';
import { DateTimeFormat } from '@ha/intl';
import { Typography } from '@hbf/dsl/core';
import { Grid, Rating } from '@hbf/dsl/legacy';
import { Star } from '@hbf/icons/brand-bold';
import { Star as FilledStart } from '@hbf/icons/brand-fill';

import { useIntl } from 'ha/i18n';

import { SectionLayout } from '../../layouts';

import { TestimonialsSectionUserAvatar } from './TestimonialsSectionUserAvatar';

const useStyles = makeStyles()(theme => ({
  container: {
    paddingBottom: 0,
    [theme.breakpoints.up('sm')]: {
      paddingBottom: 0,
    },
    [theme.breakpoints.up('md')]: {
      paddingBottom: 0,
    },
  },
  avatar: {
    width: '64px',
    height: '64px',
    marginBottom: theme.utils.spacing('ref/spacing/5'),

    [theme.breakpoints.up('md')]: {
      width: '120px',
      height: '120px',
    },
  },
  name: {
    marginBottom: theme.utils.spacing('ref/spacing/2'),
    textAlign: 'center',
  },
  ratingIcon: {
    color: theme.palette.neutral[100],
  },
}));

interface TestimonialsSectionItemProps {
  testimonial: TestimonialData;
  isVisible: boolean;
}

export const TestimonialsSectionItem: React.FC<
  TestimonialsSectionItemProps
> = ({ testimonial, isVisible }) => {
  const { classes } = useStyles();
  const { T, formatDateTime } = useIntl();

  const theme = useTheme();
  const isLargerThanMd = useMediaQuery(theme.breakpoints.up('md'));

  const date = formatDateTime(
    new Date(testimonial.date),
    DateTimeFormat.DATE_PARTIAL,
  );

  return (
    <SectionLayout
      className={classes.container}
      aria-hidden={!isVisible}
      data-test-locator="testimonials-section-item"
    >
      <SectionLayout.Container>
        <Grid
          container
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={12} md={8}>
            <Grid
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <div className={classes.avatar}>
                <TestimonialsSectionUserAvatar avatar={testimonial.avatar} />
              </div>
              <Typography
                variant="heading/desktop/h4-semibold"
                className={classes.name}
              >
                {testimonial.name}
              </Typography>
              <Grid
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                gap={7}
              >
                <Typography variant="body/lg-regular" textAlign="center">
                  {T(
                    'home.testimonial.moved_to_%s in_%s',
                    testimonial.destination,
                    date,
                  )}
                </Typography>

                <Typography
                  variant={
                    isLargerThanMd
                      ? 'heading/desktop/h3-semibold'
                      : 'heading/mobile/h3-semibold'
                  }
                  textAlign="center"
                >
                  {testimonial.description}
                </Typography>

                <Rating
                  value={testimonial.rate}
                  readOnly
                  precision={0.5}
                  emptyIcon={<Star className={classes.ratingIcon} />}
                  icon={<FilledStart className={classes.ratingIcon} />}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </SectionLayout.Container>
    </SectionLayout>
  );
};
