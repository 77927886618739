import { Dispatch, AnyAction } from 'redux';

import { UserSearch } from '@ha/api/v2/types/UserSearch';

import { getIsAuthenticated } from 'ha/modules/AuthLogic/selectors';
import { AppServices } from 'ha/services/getAppServices';
import { GlobalState } from 'ha/types/store';

import {
  CreateStartAction,
  CreateSuccessAction,
  CreateFailAction,
  ActionsTypes,
} from '../constants';
import { savedSearchLocalStore } from '../savedSearchLocalStore';

export function createStart(): CreateStartAction {
  return {
    type: ActionsTypes.CREATE_START,
  };
}

export function createSuccess(payload: UserSearch): CreateSuccessAction {
  return {
    type: ActionsTypes.CREATE_SUCCESS,
    payload,
  };
}

export function createFail(payload: Error): CreateFailAction {
  return {
    type: ActionsTypes.CREATE_FAIL,
    payload,
  };
}

const createSavedSearch =
  (searchParams: Partial<UserSearch>, userId: number | 'me' = 'me') =>
  async (
    dispatch: Dispatch<AnyAction>,
    getState: () => GlobalState,
    services: AppServices,
  ) => {
    const state = getState();
    const isAuthenticated = getIsAuthenticated(state);

    if (!isAuthenticated) {
      await savedSearchLocalStore.set(searchParams);
      return Promise.resolve();
    }

    dispatch(createStart());

    return services.apiV2
      .saveSearch(userId, searchParams)
      .then(async response => {
        await savedSearchLocalStore.set(searchParams);
        dispatch(createSuccess(response.data));
      })
      .catch((error: Error) => {
        dispatch(createFail(error));
      });
  };

export { createSavedSearch };
