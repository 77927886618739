import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { Tabs, TabsProps, units } from '@hbf/dsl/legacy';

const useStyles = makeStyles()(t => ({
  root: {
    borderRadius: units.rem(2.125),
    backgroundColor: t.palette.grey[50],
    height: units.rem(4.125),
  },
  scroller: {
    display: 'flex',
    alignItems: 'center',
    padding: units.yx(0, 0.5),
  },
  indicator: {
    display: 'none',
  },
  toggle: {
    height: '100%',
    flex: 1,
    boxShadow: 'rgba(0, 0, 0, 0.11) 0px 2px 9px 0px',
    borderRadius: units.rem(2.125),
    backgroundColor: t.palette.white.main,
  },
}));

export const StyledTabs: React.FC<TabsProps> = props => {
  const {
    classes: { toggle, ...restClasses },
  } = useStyles();

  return (
    <Tabs
      {...props}
      classes={restClasses}
      TabIndicatorProps={{
        children: <div className={toggle} />,
      }}
    />
  );
};
