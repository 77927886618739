import React from 'react';
import { useTheme } from '@mui/material';

import { makeStyles } from 'tss-react/mui';

import { Props as ImgProps, Img } from 'ha/ui/Img';
import { Breakpoint } from '@hbf/dsl/legacy';
import { buildContentfulAssetUrl } from 'ha/utils/buildContentfulAssetUrl';
import { buildContentfulAssetSrcSet } from 'ha/utils/buildContentfulAssetSrcSet';

const useStyles = makeStyles()({
  picture: {
    height: '100%',
    width: '100%',
  },
});

// array of width for browser to choose from
// most used display widths from https://www.websitedimensions.com/
const WIDTHS = [640, 1024, 1366, 1440, 1680, 1920];

interface Props {
  quality?: number;
  sizes?: { [key in Breakpoint | 'default']?: string };
  pictureProps?: React.HTMLAttributes<HTMLPictureElement>;
  format?: string;
  // allow extra widths in the srcSet
  widths?: number[];
}

const ContentfulImg: React.FC<Omit<ImgProps, 'sizes'> & Props> = ({
  src,
  quality = 70,
  sizes = {},
  widths = [],
  format = 'jpg',
  pictureProps,
  ...rest
}) => {
  const { classes, cx } = useStyles();
  const theme = useTheme();
  const { breakpoints } = theme;

  const defaultSrc = buildContentfulAssetUrl(src, {
    format,
    quality,
  });
  // if the url didn't change, it's not customizable with Contentful query params
  const isCustomizable = defaultSrc !== src;

  const currentBreakpoints = breakpoints.keys.filter(
    breakpoint => sizes[breakpoint],
  );
  const queries = currentBreakpoints.map((breakpoint, index) => {
    const minWidthQuery = `(min-width:${theme.breakpoints.values[breakpoint]}px)`;
    const nextBreakpoint = currentBreakpoints[index + 1];
    const mediaQuery = nextBreakpoint
      ? `${minWidthQuery} and (max-width:${theme.breakpoints.values[nextBreakpoint]}px)`
      : minWidthQuery;

    return `${mediaQuery} ${sizes[breakpoint]}`;
  });

  const sizesAttribute = [...queries, '100vw'].join(', ');

  return (
    <picture {...pictureProps} className={cx(classes.picture)}>
      {isCustomizable && (
        <source
          type="image/webp"
          sizes={sizesAttribute}
          srcSet={buildContentfulAssetSrcSet(src, [...widths, ...WIDTHS], {
            format: 'webp',
            quality,
          })}
        />
      )}
      <Img
        role="presentation"
        src={defaultSrc}
        sizes={sizesAttribute}
        srcSet={
          isCustomizable
            ? buildContentfulAssetSrcSet(src, [...widths, ...WIDTHS], {
                format,
                quality,
              })
            : undefined
        }
        {...rest}
      />
    </picture>
  );
};

export { ContentfulImg };
