import { ListingFacilityKey } from '@ha/api/v2/types/Listing';

export const FacilitiesValues = {
  Carpet: 'carpet',
  Central: 'central',
  Discussable: 'discussable',
  Electrical: 'electrical',
  Gas: 'gas',
  Laminate: 'laminate',
  LookingForJob: 'looking for a job',
  No: 'no',
  Other: 'other',
  OutsideOnly: 'outside_only',
  Plastic: 'plastic',
  PreferredFemale: 'female',
  PreferredMale: 'male',
  Private: 'private',
  Shared: 'shared',
  SharedWithBoth: 'shared_w_both',
  SharedWithBoys: 'shared_w_boys',
  SharedWithGirls: 'shared_w_girls',
  Stone: 'stone',
  Student: 'student',
  Studio: '0',
  Wood: 'wood',
  WoodStove: 'wood_stove',
  Working: 'working',
  WorkingStudent: 'working student',
  Yes: 'yes',
} as const;

export const FacilitiesOptions: {
  [K in ListingFacilityKey]: string[];
} = {
  // General
  allergy_friendly: [FacilitiesValues.No, FacilitiesValues.Yes],
  housemates_gender: [
    FacilitiesValues.SharedWithBoys,
    FacilitiesValues.SharedWithGirls,
    FacilitiesValues.SharedWithBoth,
  ],
  registration_possible: [FacilitiesValues.No, FacilitiesValues.Yes],
  tenant_status: [
    FacilitiesValues.Working,
    FacilitiesValues.Student,
    FacilitiesValues.WorkingStudent,
    FacilitiesValues.LookingForJob,
  ],

  // Bedroom
  bedroom_count: ['0', '1', '2', '3', '4', '5', '6', '7', '8+'],
  bedroom_furnished: [FacilitiesValues.No, FacilitiesValues.Yes],
  student_housing: [FacilitiesValues.No, FacilitiesValues.Yes],
  co_living: [FacilitiesValues.No, FacilitiesValues.Yes],
  bedroom_size: ['integer'],
  lock: [FacilitiesValues.No, FacilitiesValues.Yes],

  // Areas
  balcony_terrace: [
    FacilitiesValues.No,
    FacilitiesValues.Shared,
    FacilitiesValues.Private,
  ],
  basement: [
    FacilitiesValues.No,
    FacilitiesValues.Shared,
    FacilitiesValues.Private,
  ],
  bathroom: [
    FacilitiesValues.No,
    FacilitiesValues.Private,
    FacilitiesValues.SharedWithBoys,
    FacilitiesValues.SharedWithGirls,
    FacilitiesValues.SharedWithBoth,
  ],
  garden: [
    FacilitiesValues.No,
    FacilitiesValues.Shared,
    FacilitiesValues.Private,
  ],
  kitchen: [
    FacilitiesValues.No,
    FacilitiesValues.Shared,
    FacilitiesValues.Private,
  ],
  living_room: [
    FacilitiesValues.No,
    FacilitiesValues.Shared,
    FacilitiesValues.Private,
  ],
  parking: [
    FacilitiesValues.No,
    FacilitiesValues.Shared,
    FacilitiesValues.Private,
  ],
  toilet: [
    FacilitiesValues.No,
    FacilitiesValues.Shared,
    FacilitiesValues.Private,
  ],
  total_size: ['integer'],
  wheelchair_accessible: [FacilitiesValues.No, FacilitiesValues.Yes],

  // Utilities
  ac: [FacilitiesValues.No, FacilitiesValues.Yes],
  bed: [FacilitiesValues.No, FacilitiesValues.Yes],
  closet: [FacilitiesValues.No, FacilitiesValues.Yes],
  desk: [FacilitiesValues.No, FacilitiesValues.Yes],
  dishwasher: [FacilitiesValues.No, FacilitiesValues.Yes],
  dryer: [FacilitiesValues.No, FacilitiesValues.Yes],
  flooring: [
    FacilitiesValues.Laminate,
    FacilitiesValues.Carpet,
    FacilitiesValues.Stone,
    FacilitiesValues.Wood,
    FacilitiesValues.Plastic,
    FacilitiesValues.Other,
  ],
  heating: [
    FacilitiesValues.No,
    FacilitiesValues.Central,
    FacilitiesValues.Gas,
    FacilitiesValues.WoodStove,
    FacilitiesValues.Electrical,
  ],
  kitchenware: [
    FacilitiesValues.No,
    FacilitiesValues.Shared,
    FacilitiesValues.Private,
  ],
  lroom_furniture: [FacilitiesValues.No, FacilitiesValues.Yes],
  tv: [FacilitiesValues.No, FacilitiesValues.Yes],
  washing_machine: [FacilitiesValues.No, FacilitiesValues.Yes],
  wifi: [FacilitiesValues.No, FacilitiesValues.Yes],

  // Rules
  animal_allowed: [
    FacilitiesValues.No,
    FacilitiesValues.Yes,
    FacilitiesValues.Discussable,
  ],
  play_music: [
    FacilitiesValues.No,
    FacilitiesValues.Yes,
    FacilitiesValues.Discussable,
  ],
  smoking_allowed: [
    FacilitiesValues.No,
    FacilitiesValues.Yes,
    FacilitiesValues.Discussable,
    FacilitiesValues.OutsideOnly,
  ],
};

export const FacilitiesKeys = Object.keys(
  FacilitiesOptions,
) as readonly ListingFacilityKey[];

export const FacilitiesGroups: {
  [K in 'spaceOverview' | 'amenities' | 'rules']: ListingFacilityKey[];
} = {
  spaceOverview: [
    'parking',
    'living_room',
    'garden',
    'toilet',
    'kitchen',
    'basement',
    'bathroom',
    'balcony_terrace',
  ],
  amenities: [
    'wifi',
    'lroom_furniture',
    'bed',
    'tv',
    'lock',
    'kitchenware',
    'ac',
    'washing_machine',
    'dryer',
    'closet',
    'heating',
    'flooring',
    'dishwasher',
    'wheelchair_accessible',
    'desk',
  ],
  rules: ['play_music', 'animal_allowed', 'smoking_allowed'],
};

export const CharityListingFacilitiesToOmit = [
  'living_room',
  'balcony_terrace',
  'garden',
  'basement',
  'parking',
  'allergy_friendly',
  'closet',
  'desk',
  'tv',
  'dryer',
  'dishwasher',
  'kitchenware',
  'heating',
  'ac',
  'flooring',
  'lroom_furniture',
  'play_music',
];
