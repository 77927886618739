import { useMemo } from 'react';

import { useIntl } from 'ha/i18n';

import { buildParams } from '@ha/search-query';

export const useRoomPageLink = (
  listingPath: string,
  queryParams?: Record<string, unknown>,
) => {
  const { urlResolver } = useIntl();

  const roomLink = useMemo(() => {
    if (!listingPath) return '';

    return [
      urlResolver.formatHrefURL(listingPath),
      buildParams(queryParams || {}),
    ].join('');
  }, [urlResolver, listingPath, queryParams]);

  return roomLink;
};
