import { defineKey } from '@ha/intl';

import { AdvertiserRatingValue } from 'ha/types/SearchFilters';

export const SEARCH_FILTER_ADVERTISER_RATING = [
  AdvertiserRatingValue.OVER_THREE,
  AdvertiserRatingValue.OVER_FOUR,
  AdvertiserRatingValue.UNRATED,
] as const;

export const SEARCH_FILTER_ADVERTISER_RATING_VALUES = {
  [AdvertiserRatingValue.OVER_THREE]: 3,
  [AdvertiserRatingValue.OVER_FOUR]: 4,
  [AdvertiserRatingValue.UNRATED]: undefined,
} satisfies Record<
  (typeof SEARCH_FILTER_ADVERTISER_RATING)[number],
  number | undefined
>;

export const SEARCH_FILTER_ADVERTISER_RATING_TRANSLATIONS = {
  [AdvertiserRatingValue.OVER_THREE]: defineKey(
    'search.filters.popup.label.%d_or_higher',
  ),
  [AdvertiserRatingValue.OVER_FOUR]: defineKey(
    'search.filters.popup.label.%d_or_higher',
  ),
  [AdvertiserRatingValue.UNRATED]: defineKey(
    'search.filters.popup.label.new_landlords',
  ),
} satisfies Record<(typeof SEARCH_FILTER_ADVERTISER_RATING)[number], string>;
