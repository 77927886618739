import React from 'react';

import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/system';
import { useSelector } from 'react-redux';

import { makeStyles } from 'tss-react/mui';

import { Tabs, Typography } from '@hbf/dsl/core';

import { HOME } from 'ha/constants/pageNames';

import { useIntl } from 'ha/i18n';
import { useTrackEvent } from 'ha/modules/Analytics/helpers/TrackEvent';
import { getIsAuthenticated } from 'ha/modules/AuthLogic/selectors';
import { ScreenSize } from 'ha/modules/UI/constants';

import { TabPanel } from 'ha/components/StyledTabs';
import { SectionLayout } from 'ha/pages/Home/layouts';

import { FavoriteListingsUnauthenticatedPlaceHolder } from './FavoriteListingsPlaceHolder';
import { FavoriteListingsSlider } from './FavoriteListingsSlider';
import { RecentlyViewedListingsSlider } from './RecentlyViewedListingsSlider';

const useStyles = makeStyles()(theme => ({
  root: {
    marginTop: `-${theme.utils.spacing('ref/spacing/6')}px`,
    [theme.breakpoints.down(ScreenSize.MD)]: {
      marginTop: theme.utils.spacing('ref/spacing/0'),
    },
  },
  section: {
    [theme.breakpoints.up(ScreenSize.MD)]: {
      paddingTop: theme.utils.spacing('ref/spacing/0'),
    },
  },
  tabs: {
    marginInline: 'auto',
    maxWidth: '540px',
    marginBottom: theme.utils.spacing('ref/spacing/10'),
  },
}));

export const RecentlyViewedListings = () => {
  const { T } = useIntl();
  const { classes } = useStyles();
  const trackEvent = useTrackEvent();
  const [currentTab, setCurrentTab] = React.useState(0);
  const theme = useTheme();
  const isAuthenticated = useSelector(getIsAuthenticated);

  const isLargerThanMd = useMediaQuery(theme.breakpoints.up('md'));

  const tabsData = [
    {
      value: 0,
      label: T('recent_searches.tabs.recently_viewed'),
      testId: 'RecentlyViewedTab',
    },
    {
      value: 1,
      label: T('recent_searches.tabs.your_favorites'),
      testId: 'YourFavoritesTab',
    },
  ];

  const getTypographyVariant = (tabValue: number) => {
    const isActive = currentTab === tabValue;
    if (isLargerThanMd) {
      return isActive
        ? 'heading/desktop/h4-semibold'
        : 'heading/desktop/h4-regular';
    }
    return isActive ? 'body/lg-semibold' : 'body/lg-regular';
  };

  const renderFavoriteListingsTabContent = (tabValue: number) =>
    isAuthenticated ? (
      <FavoriteListingsSlider isActive={tabValue === currentTab} />
    ) : (
      <FavoriteListingsUnauthenticatedPlaceHolder />
    );

  const handleTabChange = React.useCallback(
    (value: React.SetStateAction<number>) => {
      if (value !== currentTab) {
        setCurrentTab(value);

        const tabName = value === 0 ? 'Recently Viewed' : 'Favorites';

        trackEvent('Tab Changed', {
          tab_name: tabName,
          page: HOME,
        });
      }
    },
    [currentTab, trackEvent],
  );

  return (
    <div className={classes.root}>
      <SectionLayout className={classes.section}>
        <Tabs
          size="sm"
          value={currentTab}
          onChange={(
            _: React.ChangeEvent<HTMLSelectElement>,
            value: number,
          ) => {
            handleTabChange(value);
          }}
          className={classes.tabs}
        >
          {tabsData.map(tab => (
            <Tabs.Item
              value={tab.value}
              key={tab.value}
              data-test-locator={`RecentlyViewedListings/${tab.testId}`}
            >
              <Typography variant={getTypographyVariant(tab.value)}>
                {tab.label}
              </Typography>
            </Tabs.Item>
          ))}
        </Tabs>
        {tabsData.map(tab => (
          <TabPanel
            currentTabIndex={currentTab}
            index={tab.value}
            key={tab.value}
          >
            {tab.value === 0 ? (
              <RecentlyViewedListingsSlider />
            ) : (
              renderFavoriteListingsTabContent(tab.value)
            )}
          </TabPanel>
        ))}
      </SectionLayout>
    </div>
  );
};
