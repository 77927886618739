import React from 'react';

import { Helmet } from 'react-helmet-async';
import { useIntl } from 'ha/i18n';

export const WebSite: React.FC = () => {
  const { urlResolver } = useIntl();

  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify({
          '@context': 'http://schema.org',
          '@type': 'WebSite',
          alternateName: 'HousingAnywhere.com',
          name: 'HousingAnywhere',
          potentialAction: {
            '@type': 'SearchAction',
            'query-input': 'required name=search_term_string',
            target: `${urlResolver.absolutizePath(`/s/{search_term_string}`)}`,
          },
          url: urlResolver.absolutizePath(`/`),
        })}
      </script>
    </Helmet>
  );
};
