import { Dispatch, AnyAction } from 'redux';

import { UserSearchList } from '@ha/api/v2/types/UserSearchList';

import { AppServices } from 'ha/services/getAppServices';

import {
  ActionsTypes,
  LoadStartAction,
  LoadSuccessAction,
  LoadFailAction,
} from '../constants';
import { savedSearchLocalStore } from '../savedSearchLocalStore';

export function loadStart(): LoadStartAction {
  return {
    type: ActionsTypes.LOAD_START,
  };
}

export function loadSuccess(payload: UserSearchList): LoadSuccessAction {
  return {
    type: ActionsTypes.LOAD_SUCCESS,
    payload,
  };
}

export function loadFail(payload: Error): LoadFailAction {
  return {
    type: ActionsTypes.LOAD_FAIL,
    payload,
  };
}

const defaultLimit = 5;

const loadSavedSearches =
  (
    userId: number | 'me' = 'me',
    query?: { language: string; limit?: number },
  ) =>
  (dispatch: Dispatch<AnyAction>, _: () => void, services: AppServices) => {
    const { language, limit } = query || {};

    dispatch(loadStart());

    return services.apiV2
      .getSavedSearches(userId, {
        lang: language || services.intl.lang,
        limit: limit || defaultLimit,
      })
      .then(response => response.data)
      .then(({ searches, count }) => {
        if (count > 0) {
          savedSearchLocalStore
            .set(searches[0])
            .then(() => {
              dispatch(loadSuccess({ searches, count }));
            })
            .catch(() => {});
        } else {
          dispatch(loadSuccess({ searches, count }));
        }
      })
      .catch((error: Error) => {
        dispatch(loadFail(error));
      });
  };

export { loadSavedSearches };
