import { redirect } from 'react-router-dom';

import { getIsAdvertiser } from 'ha/modules/AuthLogic/selectors';
import { PageLoaderFunction } from 'ha/types/routing';

import { load as loadFeaturedCities } from 'ha/modules/FeaturedCities/actions';
import { loadHermesData } from 'ha/modules/Hermes';

export { HomePage as default } from './HomePage';

export const loader: PageLoaderFunction = async ({
  context: { store, intl },
  request,
}) => {
  const { dispatch, getState } = store;
  const { lang, urlResolver } = intl;
  const url = new URL(request.url);

  const state = getState();

  const isAdvertiser = getIsAdvertiser(state);

  if (isAdvertiser && !url.searchParams.get('noredirect')) {
    // eslint-disable-next-line @typescript-eslint/no-throw-literal
    throw redirect(urlResolver.getRMSDashboardUrl());
  }

  await Promise.all([
    dispatch(loadFeaturedCities()),
    dispatch(loadHermesData(urlResolver.getHomePageDataUrl(lang))),
  ]);
};
