import {
  Listing,
  ListingPricingType,
  ListingRentalPeriodType,
} from '@ha/api/v2/types/Listing';
import { UnitType } from '@ha/api/v2/types/inventory/UnitType';

export const getUnitTypeMinPrice = (unitType: Listing | UnitType) => {
  if (
    unitType.rentalPeriodType === ListingRentalPeriodType.STRICT &&
    !!unitType?.strictRentalPeriods?.length
  ) {
    const minPriceSRP = unitType?.strictRentalPeriods.reduce(
      (min, srp) => (srp.price < min ? srp.price : min),
      unitType?.strictRentalPeriods[0].price,
    );

    return minPriceSRP;
  }

  if (
    unitType.pricingType === ListingPricingType.MONTHLY &&
    !!unitType.pricingValues.monthly
  ) {
    return Math.min(...Object.values(unitType.pricingValues.monthly));
  }

  return unitType.price;
};
