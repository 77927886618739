import { GlobalState } from 'ha/types/store';

export const getFeaturedCities = (state: GlobalState) =>
  state.featuredCities.entities;

export const getIsLoaded = (state: GlobalState) => state.featuredCities.loaded;

export const getIsLoading = (state: GlobalState) =>
  state.featuredCities.loading;

export const getIsError = (state: GlobalState) => state.featuredCities.error;
