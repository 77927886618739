import React, { useEffect } from 'react';

export const useScrollObserver = (options?: IntersectionObserverInit) => {
  const [isSectionVisible, setIsSectionVisible] = React.useState<boolean>();
  const sectionRef = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!sectionRef.current) return undefined;

    const observer = new IntersectionObserver(entries => {
      setIsSectionVisible(entries[0].isIntersecting);
    }, options);

    observer.observe(sectionRef.current);

    return () => observer.disconnect();
  }, [options, sectionRef]);

  const createEventListener = React.useCallback((callback: () => void) => {
    window.addEventListener('scroll', callback);
    window.addEventListener('resize', callback);

    const removeEventListener = () => {
      window.removeEventListener('scroll', callback);
      window.removeEventListener('resize', callback);
    };

    return {
      removeEventListener,
    };
  }, []);

  return {
    sectionRef,
    isSectionVisible,
    createEventListener,
  };
};
