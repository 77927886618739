import React from 'react';

import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/system';
import { useSelector } from 'react-redux';

import { tss } from 'tss-react/mui';

import { ListingCancellationPolicy } from '@ha/api/v2/types/Listing';
import { FavoriteIconButton } from '@hbf/dsl/brand/housingAnywhere';
import { Typography, IconButton, Highlight } from '@hbf/dsl/core';
import { Grid } from '@hbf/dsl/legacy';
import { ChevronNext, ChevronPrevious } from '@hbf/icons/brand-bold';
import {
  Images as ImagesIcon,
  Youtube as YoutubeIcon,
  GridView as GridViewIcon,
  CameraRotate as CameraRotateIcon,
  ViewInAr as ViewInArIcon,
} from '@hbf/icons/brand-regular';

import { useIntl } from 'ha/i18n';
import NoPhotoImg from 'ha/img-src/static/nophotos.png';
import { NormalizedAlgoliaListing } from 'ha/models/Listing/types';
import { useTrackEvent } from 'ha/modules/Analytics/helpers/TrackEvent';
import { getIsAdmin } from 'ha/modules/AuthLogic/selectors';
import { isEnabled, useFeatureFlags } from 'ha/modules/FeatureFlags';

import { SlideRendererParams } from 'ha/components/Slider';
import { VirtualizeSlider } from 'ha/components/Slider/VirtualizeSlider';
import { useFavoriteListingActions } from 'ha/modules/FavoriteListing';
import { Imgix } from 'ha/modules/Image/Imgix';
import { useListingMetaTitle } from 'ha/modules/Listing/useListingMetaTitle';

import { LISTING_CARD_IMAGE_PARAMS } from '../constants';

import { ListingCardProps } from './types';

const MaxPhotoCount = 5;

const useStyles = tss
  .withName('ListingCardPhotoGallery')
  .withNestedSelectors<'arrowButtonsWrapper' | 'arrowButtonDisabled'>()
  .create(({ classes, theme }) => ({
    mediaContainer: {
      position: 'relative',
      width: '100%',
      paddingBottom: '100%',

      [`:hover .${classes.arrowButtonsWrapper} .MuiButtonBase-root`]: {
        opacity: 1,
      },
    },
    media: {
      position: 'absolute',
      top: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
      width: '100%',

      [theme.breakpoints.down('sm')]: {
        pointerEvents: 'none',
      },
    },
    imageContainer: {
      position: 'relative',
      overflow: 'hidden',
      height: '100%',
      width: '100%',
      borderRadius: theme.utils.borderRadius('ref/radius/xs'),

      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },

    image: {
      width: '100%',
      height: '100%',
    },

    content: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      padding: theme.spacing(4),
      pointerEvents: 'none',
    },

    contentInteractiveElement: {
      cursor: 'pointer',
      pointerEvents: 'auto',
    },

    arrowButtonsWrapper: {},
    arrowButton: {
      opacity: 0,
      transition: 'opacity 400ms cubic-bezier(0, 0, 0, 1)',
      color: theme.palette.neutral[100],
      backgroundColor: theme.palette.neutral[50],
      ':hover': {
        backgroundColor: theme.palette.neutral[50],
      },

      [theme.breakpoints.down('sm')]: {
        opacity: 1,
      },
    },
    arrowButtonDisabled: {
      opacity: '0 !important',
      ':hover': {
        opacity: 0,
      },
    },
    indicatorDotsContainer: {
      bottom: theme.spacing(4),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    indicatorDots: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(1),
    },
    indicatorDotItem: {
      backgroundColor: theme.palette.neutral[100],
      width: theme.spacing(2),
      height: theme.spacing(2),
      borderRadius: theme.utils.borderRadius('ref/radius/lg'),
      padding: theme.spacing(0),
      opacity: 0.4,
    },

    indicatorDotItemActive: {
      opacity: 1,
    },

    moreMediaOverlay: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      background: `linear-gradient(0deg, ${theme.palette.neutral[50]} 0%, ${theme.palette.neutral[50]} 100%)`,
    },

    moreMediaText: {
      color: theme.palette.neutral[100],
    },

    mediaOverlayList: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      gap: theme.spacing(2),

      marginTop: theme.spacing(3),
    },
    mediaOverlayListItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      gap: theme.spacing(2),

      color: theme.palette.neutral[100],
    },

    newBadge: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.neutral[100],
      padding: theme.spacing(0, 2),
      ...theme.typography['body/sm-semibold'],
      borderRadius: theme.utils.borderRadius('ref/radius/xs'),
      minHeight: '28px',
    },
    listingRank: {
      position: 'absolute',
      bottom: theme.utils.spacing('ref/spacing/2'),
      right: theme.utils.spacing('ref/spacing/2'),
    },
  }));

const GalleryImage = ({
  src,
  isLazy,
  title,
  useLowQualityImagePlaceholder,
  fetchPriority = 'low',
}: {
  src: string;
  title: string;
  isLazy?: boolean;
  useLowQualityImagePlaceholder?: boolean;
  fetchPriority?: 'high' | 'low';
}) => {
  const { classes } = useStyles();

  return (
    <Imgix
      className={classes.image}
      htmlAttributes={{
        title,
        alt: title,
        itemProp: 'image',
        role: 'img',
        'data-test-locator': 'ListingCardPhotoGallery/Photo',
        fetchpriority: fetchPriority,
      }}
      isLazy={isLazy}
      useLowQualityImagePlaceholder={useLowQualityImagePlaceholder}
      src={src}
      width={LISTING_CARD_IMAGE_PARAMS.width}
      height={LISTING_CARD_IMAGE_PARAMS.height}
      imgixParams={{
        fit: 'crop',
        auto: 'format',
        ar: '1:1',
        q: LISTING_CARD_IMAGE_PARAMS.compression,
      }}
    />
  );
};

interface Props
  extends Pick<ListingCardProps, 'cardLocation' | 'cardPosition'> {
  room: NormalizedAlgoliaListing;
  isLazy?: boolean;
  withFavorites?: boolean;
  withGallery?: boolean;
}

export const ListingCardPhotoGallery: React.FC<Props> = ({
  room,
  isLazy,
  cardLocation = 'unspecified',
  withGallery = true,
  withFavorites,
  cardPosition,
}) => {
  const {
    id,
    photos,
    photoCount,
    floorPlanCount,
    videoCount,
    isNew,
    cPolicy,
    unitTypeId,
    isMultiUnit,
    has3DTour,
    photo360Count = 0,
  } = room;
  const { T } = useIntl();
  const { classes, cx } = useStyles();

  const theme = useTheme();
  const track = useTrackEvent();
  const isAdmin = useSelector(getIsAdmin);

  const { enableListingRankForAdmins } = useFeatureFlags();

  const isLargerThanLG = useMediaQuery(theme.breakpoints.up('lg'));

  const hasGallery =
    withGallery && Boolean(photos?.length && photos?.length !== 1);

  const [currentSlideIndex, setCurrentSlideIndex] = React.useState(0);

  const slideCount = Math.min(photos?.length || 0, MaxPhotoCount);

  const isFirstSlide = currentSlideIndex === 0;
  const isLastSlide = currentSlideIndex === slideCount - 1;

  const isCancellationFlexible =
    cPolicy === ListingCancellationPolicy.FLEXIBLE_30D;
  const showMoreMediaSlide =
    currentSlideIndex === slideCount - 1 && photoCount > MaxPhotoCount;

  const imageAlt = useListingMetaTitle(room);

  const {
    onClick: onFavoriteClick,
    isLoading: isFavoriteLoading,
    isFavorite,
  } = useFavoriteListingActions(unitTypeId);

  const handleFavoritesClick = React.useCallback(
    (e: React.MouseEvent) => {
      track('Favorite clicked', {
        type: 'button',
        unittypeid: unitTypeId,
        isMultiUnit,
        unitId: id,
        listingId: id,
        favoriteType: isFavorite ? 'unfavorite' : 'favorite',
        clickedOnPage: cardLocation,
      });

      onFavoriteClick(e);
    },
    [
      track,
      unitTypeId,
      isMultiUnit,
      id,
      isFavorite,
      cardLocation,
      onFavoriteClick,
    ],
  );

  const handlePrevIndex = React.useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      e.preventDefault();

      if (!isFirstSlide) {
        setCurrentSlideIndex(currentSlideIndex - 1);
      }
    },
    [currentSlideIndex, isFirstSlide],
  );

  const handleNextIndex = React.useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      e.preventDefault();
      if (!isLastSlide) {
        setCurrentSlideIndex(currentSlideIndex + 1);
      }
    },
    [currentSlideIndex, isLastSlide],
  );

  const handleIndicatorClick = (
    e: React.MouseEvent,
    indicatorIndex: number,
  ) => {
    e.stopPropagation();
    e.preventDefault();
    setCurrentSlideIndex(indicatorIndex);
  };

  const handleOverlayClick = React.useCallback(
    (e: React.MouseEvent) => {
      if (!isLastSlide) return;

      e.stopPropagation();

      track('more photos available clicked', {
        listingcity: room.city,
        listingcountry: room.country,
        ismul: room.isMultiUnit,
        cardLocation,
        position: cardPosition,
        unittypeid: room.unitTypeId,
        with_orpheus_score: room.rankWithOrpheus,
        with_orpheus_experiment_score: room.rankWithOrpheusExperiment,
      });
    },
    [
      cardLocation,
      cardPosition,
      isLastSlide,
      room.city,
      room.country,
      room.isMultiUnit,
      room.rankWithOrpheus,
      room.rankWithOrpheusExperiment,
      room.unitTypeId,
      track,
    ],
  );

  const handleChangeIndex = React.useCallback((newIndex: number) => {
    setCurrentSlideIndex(newIndex);
  }, []);

  /*
   * The slider index will always be incremental, without looping over the first index.
   * Consequently, we need another variable to calculate the indicator index for this purpose.
   */
  const indicatorIndex = React.useMemo(() => {
    return currentSlideIndex % slideCount;
  }, [currentSlideIndex, slideCount]);

  const overscanSlideAfter = React.useMemo(() => {
    const slidesLimit = Math.min(photoCount, MaxPhotoCount);

    // We want to preload all images only if user has interacted with the slider
    if (currentSlideIndex > 0) return slidesLimit;

    return 2;
  }, [currentSlideIndex, photoCount]);

  const overscanSlideBefore = React.useMemo(() => {
    const slidesLimit = Math.min(photoCount, MaxPhotoCount);

    // We want to preload all images only if user has interacted with the slider
    if (currentSlideIndex > 0) return slidesLimit;

    return 0;
  }, [currentSlideIndex, photoCount]);

  const listingPicture = React.useCallback(() => {
    return hasGallery ? (
      <VirtualizeSlider
        overscanSlideAfter={overscanSlideAfter}
        overscanSlideBefore={overscanSlideBefore}
        slideCount={slideCount}
        style={{ height: '100%', width: '100%' }}
        containerStyle={{ height: '100%' }}
        index={currentSlideIndex}
        onChangeIndex={handleChangeIndex}
        slideRenderer={({ index: currentIndex, key }: SlideRendererParams) => {
          const image = photos?.[currentIndex];

          if (!image) {
            return null;
          }

          return (
            <div className={classes.imageContainer} key={key}>
              <GalleryImage
                src={image}
                isLazy={isLazy || currentIndex > 0}
                title={imageAlt}
                useLowQualityImagePlaceholder={currentIndex === 0}
                fetchPriority={currentIndex === 0 ? 'high' : 'low'}
              />
              {currentIndex === slideCount - 1 &&
                photoCount > MaxPhotoCount && (
                  <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    direction="column"
                    className={classes.moreMediaOverlay}
                  >
                    <Typography
                      variant="body/lg-semibold"
                      className={classes.moreMediaText}
                    >
                      {T('search.listing_card.overlay.all_media')}
                    </Typography>

                    <div className={classes.mediaOverlayList}>
                      {photoCount > 0 && (
                        <div className={classes.mediaOverlayListItem}>
                          <ImagesIcon />
                          <Typography variant="body/sm-regular">
                            {T(
                              'search.listing_card.overlay.%d_photos',
                              photoCount,
                            )}
                          </Typography>
                        </div>
                      )}

                      {videoCount > 0 && (
                        <div className={classes.mediaOverlayListItem}>
                          <YoutubeIcon />
                          {videoCount === 1
                            ? T('search.listing_card.overlay.1_video')
                            : T(
                                'search.listing_card.overlay.%d_video',
                                videoCount,
                              )}
                        </div>
                      )}

                      {floorPlanCount > 0 && (
                        <div className={classes.mediaOverlayListItem}>
                          <GridViewIcon />
                          {floorPlanCount === 1
                            ? T('search.listing_card.overlay.1_floorplan')
                            : T(
                                'search.listing_card.overlay.%d_floorplan',
                                floorPlanCount,
                              )}
                        </div>
                      )}

                      {photo360Count > 0 && (
                        <div className={classes.mediaOverlayListItem}>
                          <CameraRotateIcon />
                          {T('search.listing_card.overlay.360_view')}
                        </div>
                      )}

                      {isLargerThanLG && has3DTour && (
                        <div className={classes.mediaOverlayListItem}>
                          <ViewInArIcon />
                          {T('search.listing_card.overlay.3D_view')}
                        </div>
                      )}
                    </div>
                  </Grid>
                )}
            </div>
          );
        }}
      />
    ) : (
      <div className={classes.imageContainer}>
        <GalleryImage
          src={photos?.[0] || NoPhotoImg}
          isLazy={isLazy}
          title={imageAlt}
          useLowQualityImagePlaceholder
          fetchPriority="high"
        />
      </div>
    );
  }, [
    T,
    classes,
    currentSlideIndex,
    floorPlanCount,
    handleChangeIndex,
    has3DTour,
    hasGallery,
    imageAlt,
    isLargerThanLG,
    isLazy,
    overscanSlideAfter,
    overscanSlideBefore,
    photo360Count,
    photoCount,
    photos,
    slideCount,
    videoCount,
  ]);

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
    <div
      className={classes.mediaContainer}
      data-test-locator="ListingCardPhotoGallery"
      onClick={handleOverlayClick}
    >
      <div className={classes.media}>{listingPicture()}</div>

      {isAdmin && isEnabled(enableListingRankForAdmins) && (
        <div className={classes.listingRank}>
          <Highlight
            color="secondary"
            data-test-locator="ListingCardPhotoGallery/ListingRank"
          >
            {room.rankWithOrpheus}
          </Highlight>
        </div>
      )}

      <div className={classes.content}>
        <Grid container alignContent="center" justifyContent="space-between">
          <div>
            {isCancellationFlexible && !showMoreMediaSlide && (
              <Highlight
                color="neutral"
                data-test-locator="ListingCardPhotoGallery/FlexibleCancellation"
              >
                {T('search.cancellation_policy.tag.flexible_cancellation')}
              </Highlight>
            )}

            {isNew && !isCancellationFlexible && !showMoreMediaSlide && (
              <Highlight
                color="primary"
                data-test-locator="ListingCardPhotoGallery/NewBadge"
              >
                {T('New')}
              </Highlight>
            )}
          </div>
          <div>
            {withFavorites && (
              <div className={classes.contentInteractiveElement}>
                <FavoriteIconButton
                  size="large"
                  onClick={handleFavoritesClick}
                  key={isFavorite ? 'isFavorite' : 'isNotFavorite'}
                  selected={isFavorite}
                  disabled={isFavoriteLoading}
                  data-test-locator="ListingCardPhotoGallery/FavoriteButton"
                  aria-label="Favorite button"
                />
              </div>
            )}
          </div>
        </Grid>
        <div className={classes.arrowButtonsWrapper}>
          {hasGallery && (
            <Grid item container justifyContent="space-between">
              <IconButton
                disableRipple={isFirstSlide}
                aria-label={T('Previous image')}
                className={cx(
                  classes.arrowButton,
                  classes.contentInteractiveElement,
                  { [classes.arrowButtonDisabled]: isFirstSlide },
                )}
                onClick={handlePrevIndex}
                data-test-locator="ListingCardPhotoGallery/PreviousImageButton"
              >
                <ChevronPrevious />
              </IconButton>
              <IconButton
                disableRipple={isLastSlide}
                aria-label={T('Next image')}
                className={cx(
                  classes.arrowButton,
                  classes.contentInteractiveElement,
                  { [classes.arrowButtonDisabled]: isLastSlide },
                )}
                onClick={handleNextIndex}
                data-test-locator="ListingCardPhotoGallery/NextImageButton"
              >
                <ChevronNext />
              </IconButton>
            </Grid>
          )}
        </div>

        {hasGallery && (
          <div className={classes.indicatorDotsContainer}>
            <div
              className={cx(
                classes.indicatorDots,
                classes.contentInteractiveElement,
              )}
            >
              {[...Array(slideCount)].map((_, slideIndex) => (
                <IconButton
                  className={cx(classes.indicatorDotItem, {
                    [classes.indicatorDotItemActive]:
                      slideIndex === indicatorIndex,
                  })}
                  // eslint-disable-next-line react/no-array-index-key
                  key={`Image ${slideIndex}`}
                  onClick={(e: React.MouseEvent) =>
                    handleIndicatorClick(e, slideIndex)
                  }
                  aria-label={`Image ${slideIndex}`}
                />
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
