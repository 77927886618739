import React from 'react';

import { useDispatch } from 'react-redux';

import { UserSearch } from '@ha/api/v2/types/UserSearch';

import { createSavedSearch } from 'ha/modules/SavedSearches/actions/createSavedSearch';
import { savedSearchLocalStore } from 'ha/modules/SavedSearches/savedSearchLocalStore';

export const useSavedLocalSearch = ({ isLoaded }: { isLoaded: boolean }) => {
  const [latestSavedSearch, setLatestSavedSearch] =
    React.useState<Partial<UserSearch> | null>();
  React.useEffect(() => {
    if (isLoaded) {
      setLatestSavedSearch(savedSearchLocalStore.get());
    }
  }, [isLoaded]);

  const dispatch = useDispatch();
  React.useEffect(() => {
    if (!latestSavedSearch) return;
    dispatch(createSavedSearch(latestSavedSearch));
  }, [dispatch, latestSavedSearch]);

  return latestSavedSearch;
};
