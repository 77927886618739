import { defineKey } from '@ha/intl';

import { RoomsValue, TypeValue } from 'ha/types/SearchFilters';

export const SEARCH_FILTER_PROPERTY_TYPES = [
  TypeValue.SHARED_ROOM,
  TypeValue.PRIVATE_ROOM,
  TypeValue.STUDIO,
  TypeValue.APARTMENT,
  TypeValue.STUDENT_HOUSING,
] as const;

export const SEARCH_FILTER_PROPERTY_TYPE_TRANSLATIONS = {
  [TypeValue.STUDIO]: defineKey('search.page.chip.label.studio'),
  [TypeValue.APARTMENT]: defineKey('search.page.chip.label.apartment'),
  [TypeValue.PRIVATE_ROOM]: defineKey('search.page.chip.label.private_room'),
  [TypeValue.SHARED_ROOM]: defineKey('search.page.chip.label.shared_room'),
  [TypeValue.STUDENT_HOUSING]: defineKey(
    'search.page.chip.label.student_residence',
  ),
};

export const SEARCH_FILTER_ROOMS = [
  RoomsValue.ONE,
  RoomsValue.TWO,
  RoomsValue.THREE,
  RoomsValue.FOUR_AND_MORE,
];

export const SEARCH_FILTER_ROOMS_TRANSLATIONS = {
  [RoomsValue.ONE]: defineKey('search.page.chip.label.1_bedroom'),
  [RoomsValue.TWO]: defineKey('search.page.chip.label.%s_bedrooms'),
  [RoomsValue.THREE]: defineKey('search.page.chip.label.%s_bedrooms'),
  [RoomsValue.FOUR_AND_MORE]: defineKey('search.page.chip.label.%s_bedrooms'),
};
