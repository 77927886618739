import { PropertyType } from '@ha/algolia';
import {
  ListingCancellationPolicy,
  ListingExclusion,
  ListingRentalPeriodType,
  ListingStrictRentalPeriod,
} from '@ha/api/v2/types/Listing';
import { LandlordType } from '@ha/api/v2/types/User';

import { Currency } from 'ha/constants/Currencies';

import { DiscountInfo } from 'ha/modules/PaymentDetailsDrawer/types';

export type UtilityBills = 'I' | 'E' | 'P';

export enum UtilityBillsValues {
  INCLUDED = 'I',
  EXCLUDED = 'E',
  PARTIALLY = 'P',
}

export type DisplayedPriceInfo =
  | {
      type: 'flatPrice';
      value: number;
      bills: UtilityBills;
      currency: string;
      discountInfo?: DiscountInfo;
    }
  | { type: 'free' }
  | { type: 'fromPrice'; value: number; bills: UtilityBills; currency: string }
  | {
      type: 'priceRange';
      valueFrom: number;
      valueTo: number;
      bills: UtilityBills;
      currency: string;
    };

export type SimplifiedListingExclusion = Pick<ListingExclusion, 'from' | 'to'>;

export interface NormalizedAlgoliaListing {
  id: number;
  advertiserId?: number;
  price: number;
  currency: Currency;
  street: string;
  city: string;
  country: string;
  isNew: boolean;
  previewImage: string;
  photos?: string[];
  photoCount: number;
  floorPlanCount: number;
  videoCount: number;
  minimalRentalPeriod: number;
  maximumStay: number;
  listingPath: string;
  propertyType: PropertyType;
  propertyId?: number;
  exclusions?: Array<{
    from: string;
    to: string;
  }>;
  bedroomFurnished?: string;
  utilities?: UtilityBills;
  bedroomCount?: string;
  totalSize?: string;
  bedroomSize?: string;
  currentOccupancy?: number;
  freePlaces?: number;
  isPartner?: boolean;
  registrationPossible?: string;
  cPolicy?: ListingCancellationPolicy;
  priceType?: string;
  minPrice?: number;
  maxPrice?: number;
  isMultiUnit?: boolean;
  unitTypePath?: string;
  unitTypeId: number;
  studentHousing?: string;
  strictRentalPeriods?: ListingStrictRentalPeriod[] | null;
  coLiving?: string;
  rentalPeriodType: ListingRentalPeriodType;
  advertiserFirstName?: string;
  advertiserMoveInOverallRating?: number;
  advertiserMoveInReviewCount?: number;
  isExcellentAdvertiser?: boolean;
  countryCode?: string;
  pricingValues: {
    flat: number;
    monthly: Record<string, number> | null;
    precise?: Record<string, number>;
  };
  pricingValuesEUR: {
    flat: number;
    monthly: Record<string, number> | null;
    precise?: Record<string, number>;
  };
  landlordType?: LandlordType;
  has3DTour?: boolean;
  photo360Count?: number;
  rankWithOrpheus?: number;
  rankWithOrpheusExperiment?: number;
}
