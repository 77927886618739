import { CurrencyFormat } from '@ha/intl';

import { useIntl } from 'ha/i18n';
import { NormalizedAlgoliaListing } from 'ha/models/Listing/types';
import { getListingTypeTitle } from 'ha/modules/Listing/helpers';
import trimStart from 'lodash/trimStart';
import { useListingSizeInfo } from './useListingSizeInfo';

export const useListingAnchorTitle = (props: NormalizedAlgoliaListing) => {
  const { city, price, currency, propertyType } = props;
  const { isAptSizePresented, listingSize } = useListingSizeInfo({ ...props });

  const { T, formatCurrency } = useIntl();
  const listingTypeLabel = getListingTypeTitle(T, propertyType);
  const formattedPrice = formatCurrency(
    price,
    currency,
    CurrencyFormat.WITH_CURRENCY_SYMBOL_NO_FRACTION,
  );

  const anchorTitle = T(
    'listing_card.%s_%s_for_%s_%s_in_%s',
    isAptSizePresented ? listingSize : '',
    listingTypeLabel,
    formattedPrice,
    T('search.listing_card.price.per_month'),
    city,
  );

  return {
    anchorTitle: trimStart(anchorTitle),
  };
};
