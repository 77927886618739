import React from 'react';
import SwipeableViews from 'react-swipeable-views';
import { virtualize, mod } from './reactSwipeableViews';
import { SlideRendererParams, VirtualizeSliderProps } from './types';

export const VirtualizeSwipeableViews = virtualize(SwipeableViews);

export const VirtualizeSlider: React.FC<VirtualizeSliderProps> = ({
  index,
  slideCount,
  style,
  containerStyle,
  slideStyle,
  slideRenderer,
  onChangeIndex,
  onSwitching,
  overscanSlideAfter,
  overscanSlideBefore,
  enableMouseEvents,
}) => (
  <VirtualizeSwipeableViews
    data-test-locator="Slider VirtualizeSlider"
    index={index}
    onChangeIndex={onChangeIndex}
    style={style}
    containerStyle={containerStyle}
    slideStyle={slideStyle}
    ignoreNativeScroll
    enableMouseEvents={enableMouseEvents}
    onSwitching={onSwitching}
    overscanSlideAfter={overscanSlideAfter}
    overscanSlideBefore={overscanSlideBefore}
    slideRenderer={(params: SlideRendererParams) =>
      slideRenderer({
        index: mod(params.index, slideCount),
        key: params.key,
      })
    }
  />
);
