import React from 'react';
import { filterBuilder, getBoundaryCoordinates } from '@ha/algolia';
import { useServices } from 'ha/services';
import { useSelector } from 'react-redux';
import { getFeaturedCities } from 'ha/modules/FeaturedCities/selectors';

interface CityListingsCountsMap {
  [key: string]: number;
}

export const useListingsCount = () => {
  const services = useServices();
  const cities = useSelector(getFeaturedCities);
  const [listingsCount, setListingsCount] =
    React.useState<CityListingsCountsMap>({});

  const queryFilterBuilder = React.useMemo(() => filterBuilder({}), []);

  const getListingsCount = React.useCallback(async () => {
    const queries = cities.map(({ latitude, longitude, mapRadius }) => ({
      query: '',
      params: {
        distinct: true,
        insideBoundingBox: [
          getBoundaryCoordinates(latitude, longitude, mapRadius / 1000),
        ],
        responseFields: ['nbHits'],
        filters: queryFilterBuilder({}),
      },
    }));

    const algoliaResult = await services.algolia.searchListings(queries);

    const listingsCountPerCity = algoliaResult.results.reduce(
      (result: CityListingsCountsMap, { nbHits }, index: number) => {
        const { cityCanonical } = cities[index];
        if (cityCanonical) {
          result[cityCanonical] = nbHits;
        }

        return result;
      },
      {},
    );
    setListingsCount(listingsCountPerCity);
  }, [cities, queryFilterBuilder, services.algolia]);

  return {
    getListingsCount,
    listingsCount,
  };
};
