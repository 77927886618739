import { defineKey } from '@ha/intl';

import { FacilitiesValue } from 'ha/types/SearchFilters';

export const SEARCH_FILTER_FACILITIES = [
  FacilitiesValue.PRIVATE_BATHROOM,
  FacilitiesValue.BALCONY_TERRACE,
  FacilitiesValue.GARDEN,
  FacilitiesValue.KITCHEN,
  FacilitiesValue.PET_FRIENDLY,
  FacilitiesValue.PARKING,
  FacilitiesValue.WHEELCHAIR_ACCESSIBLE,
  FacilitiesValue.BASEMENT,
] as const;

export const SEARCH_FILTER_FACILITIES_TRANSLATIONS = {
  [FacilitiesValue.BALCONY_TERRACE]: defineKey(
    'search.filters.popup.label.balcony_terrace',
  ),
  [FacilitiesValue.BASEMENT]: defineKey('search.filters.popup.label.basement'),
  [FacilitiesValue.GARDEN]: defineKey('search.filters.popup.label.garden'),
  [FacilitiesValue.KITCHEN]: defineKey('search.filters.popup.label.kitchen'),
  [FacilitiesValue.PARKING]: defineKey('search.filters.popup.label.parking'),
  [FacilitiesValue.PET_FRIENDLY]: defineKey(
    'search.filters.popup.label.pets_allowed',
  ),
  [FacilitiesValue.PRIVATE_BATHROOM]: defineKey(
    'search.filters.popup.label.private_bathroom',
  ),
  [FacilitiesValue.WHEELCHAIR_ACCESSIBLE]: defineKey(
    'search.filters.popup.label.wheelchair_accessible',
  ),
};
