import { TranslateFunc } from '@ha/intl';
import { PropertyTypeTranslations } from 'ha/models/Listing/translations';
import { PropertyType } from '@ha/algolia';

export function getListingTypeTitle(
  T: TranslateFunc,
  propertyType: PropertyType,
) {
  if (propertyType === PropertyType.unknown || !propertyType) {
    return T('Room');
  }

  return T(PropertyTypeTranslations[propertyType] || propertyType);
}
