import React from 'react';

import { useMediaQuery } from '@mui/material';
import { ThemeProvider, useTheme } from '@mui/system';
import { useDispatch } from 'react-redux';

import { scroller } from 'react-scroll';
import { makeStyles } from 'tss-react/mui';

import { Link, Typography, units } from '@hbf/dsl/core';
import { theme as legacyTheme, Grid, Paper, NoSSR } from '@hbf/dsl/legacy';
import {
  ChevronDown as ChevronDownIcon,
  TenantProtection as TenantProtectionIcon,
} from '@hbf/icons/brand-bold';

import { useIntl } from 'ha/i18n';
import { useTrackEvent } from 'ha/modules/Analytics/helpers/TrackEvent';

import { loadSavedSearches } from 'ha/modules/SavedSearches/actions/loadSavedSearches';
import { SearchBar } from 'ha/pages/Home/components/SearchBar';

import { MostRecentSearch } from '../../components/MostRecentSearch';

import { HeroSectionFooterDesktop } from './HeroSectionFooterDesktop';
import { HeroSectionFooterMobile } from './HeroSectionFooterMobile';

const useStyles = makeStyles()(theme => ({
  container: {
    backgroundColor: theme.palette.primary.main,
    paddingTop: theme.utils.spacing('ref/spacing/10'),

    [theme.breakpoints.up('md')]: {
      paddingTop: theme.utils.spacing('ref/spacing/20'),
    },
  },
  contents: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    marginBottom: theme.utils.spacing('ref/spacing/10'),

    gap: theme.utils.spacing('ref/spacing/3'),

    [theme.breakpoints.up('md')]: {
      marginBottom: theme.utils.spacing('ref/spacing/5'),
    },
  },
  headline: {
    color: theme.palette.neutral[100],
    marginBottom: theme.utils.spacing('ref/spacing/2'),
  },
  subtitle: {
    color: theme.palette.neutral[100],
    marginBottom: theme.utils.spacing('ref/spacing/3'),
  },
  text: {
    color: theme.palette.neutral[100],
  },
  recentSearch: {
    width: '100%',
    paddingBlock: theme.utils.spacing('ref/spacing/0'),
  },
  searchBar: {
    width: '100%',
    padding: theme.utils.spacing('ref/spacing/5'),

    [theme.breakpoints.up('md')]: {
      paddingInline: theme.utils.spacing('ref/spacing/10'),
      paddingBlock: theme.utils.spacing('ref/spacing/4'),

      maxWidth: units.rem(70),
    },
  },
  paper: {
    // TODO: change it with shape object when it's ready.
    borderRadius: '6px',
  },

  rentOut: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.utils.spacing('ref/spacing/5'),
    gap: theme.utils.spacing('ref/spacing/2'),
  },
  rentOutButton: {
    textDecoration: 'underline',
  },
}));

export const HeroSection = ({
  searchInputRef,
  footerSectionColor,
}: {
  searchInputRef?: React.RefObject<HTMLInputElement>;
  footerSectionColor?: string;
}) => {
  const { T } = useIntl();
  const { classes, cx } = useStyles();
  const theme = useTheme();
  const isLargerThanMd = useMediaQuery(theme.breakpoints.up('md'));

  const trackEvent = useTrackEvent();

  const scrollToGuaranteedSection = () => {
    trackEvent('Clicked Tenant Protection', {
      location: 'Hero',
    });
    scroller.scrollTo('HomePage.GuaranteedSection.Handle', {
      duration: 1000,
      offset: -25,
      smooth: true,
    });
  };

  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(loadSavedSearches());
  }, [dispatch]);

  return (
    <div className={classes.container} data-test-locator="HeroSectionNext">
      <Grid container>
        <Grid item xs={12} className={classes.contents}>
          <Typography
            variant={
              isLargerThanMd ? 'display/desktop/lg' : 'display/mobile/lg'
            }
            component="h1"
            className={classes.headline}
            data-test-locator="HeroSection/Headline"
          >
            {T('home.hero_title')}
          </Typography>
          <Typography
            variant={
              isLargerThanMd
                ? 'heading/desktop/h3-semibold'
                : 'heading/mobile/h3-semibold'
            }
            component="h1"
            className={classes.subtitle}
            data-test-locator="HeroSection/Subtitle"
          >
            {T('home.hero_subtitle')}
          </Typography>
          <Link
            size="lg"
            underline="dotted"
            interactive
            onClick={scrollToGuaranteedSection}
            startIcon={<TenantProtectionIcon />}
            endIcon={<ChevronDownIcon />}
            className={classes.text}
          >
            {T('label.covered_tenant_protection')}
          </Link>
        </Grid>
      </Grid>

      {isLargerThanMd && (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <Grid item xs={12} className={classes.searchBar}>
            <Paper classes={{ root: classes.paper }}>
              <ThemeProvider theme={legacyTheme}>
                <SearchBar searchInputRef={searchInputRef} />
              </ThemeProvider>
            </Paper>
          </Grid>
          <Grid
            item
            xs={12}
            className={cx(classes.searchBar, classes.recentSearch)}
          >
            <NoSSR>
              <MostRecentSearch />
            </NoSSR>
          </Grid>
        </Grid>
      )}

      <div>
        {isLargerThanMd ? (
          <HeroSectionFooterDesktop footerSectionColor={footerSectionColor} />
        ) : (
          <HeroSectionFooterMobile>
            <ThemeProvider theme={legacyTheme}>
              <SearchBar searchInputRef={searchInputRef} />
            </ThemeProvider>
          </HeroSectionFooterMobile>
        )}
      </div>
    </div>
  );
};
