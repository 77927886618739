import React from 'react';

import { makeStyles } from 'tss-react/mui';

import { Grid, NoSSR } from '@hbf/dsl/legacy';

import { CurveSVG } from 'ha/components/CurveSVG';
import { usePageComponentsInfo } from 'ha/modules/Page/contexts/PageComponentsInfoContext';

import { MostRecentSearch } from '../../components/MostRecentSearch/MostRecentSearch';

import { HeroSectionIntroImage } from './HeroSectionIntroImage';

const useStyles = makeStyles<{ bottomNavbarHeight?: number }>()(
  (theme, { bottomNavbarHeight }) => ({
    container: {
      position: 'sticky',
      bottom: `${bottomNavbarHeight ?? 0}px`,
      textAlign: 'center',
    },
    searchBar: {
      padding: theme.utils.spacing('ref/spacing/5'),
      backgroundColor: theme.palette.neutral[100],
    },
    svgCurve: {
      fill: theme.palette.neutral[100],
    },
    recentSearchMobile: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: theme.utils.spacing('ref/spacing/5'),
    },
  }),
);

export const HeroSectionFooterMobile = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren
>(({ children }, ref) => {
  const { bottomNavbarHeight } = usePageComponentsInfo();
  const { classes } = useStyles({ bottomNavbarHeight });

  return (
    <React.Fragment>
      <HeroSectionIntroImage />
      <div className={classes.container} ref={ref}>
        <CurveSVG className={classes.svgCurve} />
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={12} className={classes.searchBar}>
            {children}
            <div
              className={classes.recentSearchMobile}
              data-test-locator="HeroSectionFooterMobile/RentOutButton"
            >
              <NoSSR>
                <MostRecentSearch />
              </NoSSR>
            </div>
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
});
