import React from 'react';

import { PropertyType } from '@ha/algolia';
import { isNorthAmericanCountry } from '@ha/core/SupportedCountries';

import { getAreaLabel } from 'ha/helpers/unitConverter';
import { NormalizedAlgoliaListing } from 'ha/models/Listing/types';

export const useListingSizeInfo = ({
  countryCode,
  propertyType,
  bedroomSize,
  totalSize,
}: NormalizedAlgoliaListing) => {
  const isImperialSystem = isNorthAmericanCountry(countryCode);

  const convertedBedroomSize = React.useMemo(
    () => getAreaLabel(bedroomSize, isImperialSystem),
    [isImperialSystem, bedroomSize],
  );
  const convertedTotalSize = React.useMemo(
    () => getAreaLabel(totalSize, isImperialSystem),
    [isImperialSystem, totalSize],
  );

  const isPrivateOrSharedRoom =
    propertyType === PropertyType.privateRoom ||
    propertyType === PropertyType.sharedRoom;

  const isAptSizePresented =
    Boolean(!isPrivateOrSharedRoom && totalSize) ||
    Boolean(isPrivateOrSharedRoom && bedroomSize);

  const size = isPrivateOrSharedRoom
    ? convertedBedroomSize
    : convertedTotalSize;

  return {
    listingSize: size,
    isAptSizePresented,
  };
};
