import { defineKey } from '@ha/intl';

import { FurnitureValue } from 'ha/types/SearchFilters';

export const SEARCH_FILTER_FURNITURE = [
  FurnitureValue.FURNISHED,
  FurnitureValue.UNFURNISHED,
] as const;

export const SEARCH_FILTER_FURNITURE_TRANSLATIONS = {
  [FurnitureValue.FURNISHED]: defineKey('search.filters.popup.label.furnished'),
  [FurnitureValue.UNFURNISHED]: defineKey(
    'search.filters.popup.label.unfurnished',
  ),
};

export const SEARCH_FILTER_FURNITURE_LOWERCASED_TRANSLATIONS = {
  [FurnitureValue.FURNISHED]: defineKey(
    'search.filters.popup.label.furnished_lowercase',
  ),
  [FurnitureValue.UNFURNISHED]: defineKey(
    'search.filters.popup.label.unfurnished_lowercase',
  ),
};
