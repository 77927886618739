import { AlgoliaListing } from '@ha/algolia';
import { Listing } from '@ha/api/v2/types/Listing';
import { FacilitiesValues } from './constants';

export function isListingFurnished({
  facilities,
}: Pick<Listing, 'facilities'>) {
  return (
    facilities.bedroom_furnished === FacilitiesValues.Yes ||
    facilities.bed === FacilitiesValues.Yes
  );
}

export function isAlgoliaListingFurnished({
  facility_bedroom_furnished,
  facility_bed,
}: Pick<AlgoliaListing, 'facility_bedroom_furnished' | 'facility_bed'>) {
  return (
    facility_bedroom_furnished === FacilitiesValues.Yes ||
    facility_bed === FacilitiesValues.Yes
  );
}
