import React from 'react';

import { Typography } from '@hbf/dsl/core';

import { useIntl } from 'ha/i18n';

import { BasePlaceHolderLayout } from './BasePlaceHolderLayout';

export const FavoriteListingsEmptyStatePlaceHolder = () => {
  const { T } = useIntl();

  return (
    <BasePlaceHolderLayout data-test-locator="FavoriteListingsEmptyStatePlaceHolder">
      <Typography variant="body/lg-regular">
        {T('recent_searches.tabs.empty_favorites')}
      </Typography>
    </BasePlaceHolderLayout>
  );
};
