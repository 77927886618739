import React from 'react';

import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/system';

import { makeStyles } from 'tss-react/mui';

import { units } from '@hbf/dsl/core';

import { LargeSVG } from './images/curve-lg.svg';
import { MediumSVG } from './images/curve-md.svg';
import { SmallSVG } from './images/curve-xs.svg';

const useStyles = makeStyles()(() => ({
  image: {
    display: 'block',
    width: '100%',
    marginBottom: units.pxToRem(-2),
  },
}));

export type CurveSVGProps = React.SVGProps<SVGSVGElement>;

export const CurveSVG: React.FC<CurveSVGProps> = ({ className, ...rest }) => {
  const { classes, cx } = useStyles();
  const theme = useTheme();
  const isLargerThanSM = useMediaQuery(theme.breakpoints.up('sm'));
  const isLargerThanMD = useMediaQuery(theme.breakpoints.up('md'));

  if (isLargerThanMD) {
    return <LargeSVG className={cx(classes.image, className)} {...rest} />;
  }

  if (isLargerThanSM) {
    return <MediumSVG className={cx(classes.image, className)} {...rest} />;
  }

  return <SmallSVG className={cx(classes.image, className)} {...rest} />;
};
