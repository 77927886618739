import React from 'react';

import { Rating } from '@mui/material';

import { makeStyles } from 'tss-react/mui';

import { Typography } from '@hbf/dsl/core';
import { Star } from '@hbf/icons/brand-bold';
import { Star as FilledStart } from '@hbf/icons/brand-fill';
import {
  Student as StudentIcon,
  Users as UsersIcon,
  House as HouseIcon,
} from '@hbf/icons/brand-regular';

import { LandlordType } from 'ha/constants/UserType';

import { showAdvertiserReviewsSummary } from 'ha/helpers/showAdvertiserReviewsSummary';
import { useIntl } from 'ha/i18n';
import { NormalizedAlgoliaListing } from 'ha/models/Listing/types';
import { FacilitiesValues } from 'ha/models/ListingFacilities/constants';

const useStyles = makeStyles()(theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(3),
  },
  ratingSection: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  ratingIcon: {
    color: theme.palette.warning.main,
    width: theme.spacing(4),
    height: theme.spacing(4),
  },

  advertiserType: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  advertiserTypeIcon: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    color: theme.palette.secondary.light,
  },
  advertiserTypeTitle: {
    wordBreak: 'break-word',
  },
}));

const AdvertiserTypeTitle = ({ children }: React.PropsWithChildren) => {
  const { classes } = useStyles();
  return (
    <Typography
      variant="body/sm-regular"
      color="secondary"
      className={classes.advertiserTypeTitle}
    >
      {children}
    </Typography>
  );
};

interface Props {
  moveInOverallRating?: number;
  moveInRatingsCount?: number;
  country?: string;
  room: NormalizedAlgoliaListing;
}

export const ListingCardInfoFooter: React.FC<Props> = ({
  moveInOverallRating,
  moveInRatingsCount,
  country,
  room: { studentHousing, coLiving, landlordType },
}) => {
  const { classes } = useStyles();
  const { T } = useIntl();

  const advertiserType = React.useMemo(() => {
    if (coLiving === FacilitiesValues.Yes) {
      return (
        <React.Fragment>
          <UsersIcon className={classes.advertiserTypeIcon} />
          <AdvertiserTypeTitle>
            {T('search.listing_card.advertiser_type.co_living')}
          </AdvertiserTypeTitle>
        </React.Fragment>
      );
    }

    if (studentHousing === FacilitiesValues.Yes) {
      return (
        <React.Fragment>
          <StudentIcon className={classes.advertiserTypeIcon} />
          <AdvertiserTypeTitle>
            {T('search.listing_card.advertiser_type.student_housing')}
          </AdvertiserTypeTitle>
        </React.Fragment>
      );
    }

    if (
      landlordType === LandlordType.STUDENT ||
      landlordType === LandlordType.PRIVATE_LANDLORD
    ) {
      return (
        <React.Fragment>
          <HouseIcon className={classes.advertiserTypeIcon} />
          <AdvertiserTypeTitle>
            {T('search.listing_card.advertiser_type.private_landlord')}
          </AdvertiserTypeTitle>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <HouseIcon className={classes.advertiserTypeIcon} />
        <AdvertiserTypeTitle>
          {T('search.listing_card.advertiser_type.rental_company')}
        </AdvertiserTypeTitle>
      </React.Fragment>
    );
  }, [studentHousing, coLiving, landlordType, classes.advertiserTypeIcon, T]);

  const shouldShowMoveInReviewSummary =
    moveInOverallRating && moveInRatingsCount
      ? showAdvertiserReviewsSummary(
          moveInRatingsCount,
          moveInOverallRating,
          country,
        )
      : false;

  return (
    <div className={classes.container}>
      <div className={classes.advertiserType}>{advertiserType}</div>
      {shouldShowMoveInReviewSummary && (
        <div className={classes.ratingSection}>
          <Rating
            value={1}
            max={1}
            readOnly
            precision={0.5}
            emptyIcon={<Star className={classes.ratingIcon} />}
            icon={<FilledStart className={classes.ratingIcon} />}
            data-test-locator="ListingCardInfoFooter.Rating"
          />
          <Typography variant="body/sm-regular" color="secondary">
            {`${parseFloat(`${moveInOverallRating}`).toFixed(
              1,
            )} (${moveInRatingsCount})`}
          </Typography>
        </div>
      )}
    </div>
  );
};
