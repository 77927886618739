import React from 'react';

import { useMediaQuery } from '@mui/material';

import { makeStyles } from 'tss-react/mui';

import { useConfig } from 'ha/helpers/config';

import { LISTING_CARD_IMAGE_PARAMS } from 'ha/components/Redesign/ListingCard/constants';
import { ImgixSize } from 'ha/modules/Image/constants';
import { Imgix } from 'ha/modules/Image/Imgix';
import { HOME_IMGIX_PATH } from 'ha/pages/Home/constants';

const useStyles = makeStyles()(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: theme.utils.spacing('ref/spacing/10'),
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.utils.spacing('ref/spacing/3'),
    textAlign: 'center',
  },
  imageContainer: {
    position: 'relative',
    display: 'inline-block',
  },
  heartIconWrapper: {
    position: 'absolute',
    top: `-${theme.utils.spacing('ref/spacing/3')}px`,
    right: `-${theme.utils.spacing('ref/spacing/4')}px`,
  },
  placeholderImage: {
    width: '100%',
    maxWidth: ImgixSize.xxsmall.w,
    height: 'auto',
  },
  heartIcon: {
    width: '100%',
    height: '100%',
  },
}));

interface Props {
  'data-test-locator'?: string;
}

export const BasePlaceHolderLayout = ({
  children,
  'data-test-locator': dataTestId,
}: React.PropsWithChildren<Props>) => {
  const { classes, theme } = useStyles();

  const isLargerThanMd = useMediaQuery(theme.breakpoints.up('md'));

  const { imgix } = useConfig();
  const favoritesPlaceholderImgSrc = `${imgix.sourceURL}/${HOME_IMGIX_PATH}/recent-listings/favorites-placeholder.jpg`;
  const favoritesHeartImgSrc = `${imgix.sourceURL}/${HOME_IMGIX_PATH}/recent-listings/favorites-heart.png`;
  const cardWidth = isLargerThanMd ? ImgixSize.xxsmall.w : ImgixSize.xxxsmall.w;

  const renderImage = () =>
    isLargerThanMd ? (
      <div className={classes.imageContainer}>
        <Imgix
          src={favoritesPlaceholderImgSrc}
          htmlAttributes={{
            alt: 'Listing card',
            className: classes.placeholderImage,
          }}
          width={cardWidth}
          imgixParams={{
            fit: 'crop',
            auto: 'format',
            q: LISTING_CARD_IMAGE_PARAMS.compression,
          }}
          isLazy
          useLowQualityImagePlaceholder
        />
        <div className={classes.heartIconWrapper}>
          <Imgix
            width={ImgixSize.thumbnailSmall.w}
            src={favoritesHeartImgSrc}
            htmlAttributes={{
              alt: 'Favorites heart',
              className: classes.heartIcon,
            }}
          />
        </div>
      </div>
    ) : (
      <Imgix
        src={favoritesHeartImgSrc}
        htmlAttributes={{ alt: 'Favorites heart' }}
        width={cardWidth}
      />
    );

  return (
    <div className={classes.root} data-test-locator={dataTestId}>
      {renderImage()}
      <div className={classes.contentContainer}>{children}</div>
    </div>
  );
};
