import { UserSearchFilters } from '@ha/api/v2/types/UserSearchFilters';
import { NO_START, NO_END } from '@ha/date';
import { CurrencyFormat, DateTimeFormat } from '@ha/intl';

import {
  convertSquareMeterToSquareFoot,
  getAreaUnit,
} from 'ha/helpers/unitConverter';
import { useIntl } from 'ha/i18n';
import {
  BillsValue,
  SuitableForValue,
  AdvertiserRatingValue,
  TypeValue,
} from 'ha/types/SearchFilters';

import {
  SEARCH_FILTER_ADVERTISER_RATING_TRANSLATIONS,
  SEARCH_FILTER_ADVERTISER_RATING_VALUES,
} from 'ha/modules/Search/translations/advertiserRating';
import { SEARCH_FILTER_AMENITIES_TRANSLATIONS } from 'ha/modules/Search/translations/amenities';
import { SEARCH_FILTERS_BILLS_INCLUDED_TRANSLATION } from 'ha/modules/Search/translations/bills';
import { SEARCH_FILTER_CONTRACT_TYPE_TRANSLATIONS } from 'ha/modules/Search/translations/contractType';
import { SEARCH_FILTER_FACILITIES_TRANSLATIONS } from 'ha/modules/Search/translations/facilities';
import { SEARCH_FILTER_FURNITURE_TRANSLATIONS } from 'ha/modules/Search/translations/furniture';
import { SEARCH_FILTER_GENDER_TRANSLATIONS } from 'ha/modules/Search/translations/gender';
import { SEARCH_FILTER_OCCUPATION_TRANSLATIONS } from 'ha/modules/Search/translations/occupation';
import {
  SEARCH_FILTER_PROPERTY_TYPE_TRANSLATIONS,
  SEARCH_FILTER_ROOMS_TRANSLATIONS,
} from 'ha/modules/Search/translations/propertyType';
import { SEARCH_FILTER_REGISTRATION_POSSIBLE_TRANSLATION } from 'ha/modules/Search/translations/registration';

export const useConstructSearchFilters = (filters?: UserSearchFilters) => {
  const { T, formatCurrency, formatDateTime } = useIntl();
  const {
    startDate,
    endDate,
    priceMax,
    priceMin,
    currency,
    categories,
    bills,
    furniture,
    gender,
    rooms,
    registration,
    suitableFor,
    sizeMin,
    sizeMax,
    contractType,
    amenities,
    facilities,
    advRating,
  } = filters || {};

  const constructPriceFilter = () => {
    if (priceMin && priceMax && currency) {
      return [
        formatCurrency(
          priceMin,
          currency,
          CurrencyFormat.WITH_CURRENCY_SYMBOL_NO_FRACTION_NO_GROUPING,
        ),
        formatCurrency(
          priceMax,
          currency,
          CurrencyFormat.WITH_CURRENCY_SYMBOL_NO_FRACTION_NO_GROUPING,
        ),
      ].join(' - ');
    }

    return '';
  };

  const constructBillsIncluded = () => {
    return bills?.includes(BillsValue.INCLUDED)
      ? T(SEARCH_FILTERS_BILLS_INCLUDED_TRANSLATION.toString())
      : '';
  };

  const constructDateFilter = () => {
    if (!startDate || startDate === NO_START) return '';

    return [
      formatDateTime(new Date(startDate), DateTimeFormat.DATE_SHORT),
      !endDate || endDate === NO_END
        ? T('header.search.no_end')
        : formatDateTime(new Date(endDate), DateTimeFormat.DATE_SHORT),
    ].join(' - ');
  };

  const constructRooms = () => {
    return rooms?.bedroomCount
      ?.map(count => T(SEARCH_FILTER_ROOMS_TRANSLATIONS[count], count))
      .filter(i => i !== '')
      .join(', ');
  };

  const constructCategories = () => {
    return (
      categories
        ?.map(category => {
          if (!category) return '';

          let propType = T(SEARCH_FILTER_PROPERTY_TYPE_TRANSLATIONS[category]);

          if (category === TypeValue.APARTMENT) {
            const roomFilters = constructRooms();
            propType = roomFilters ? `${propType} (${roomFilters})` : propType;
          }

          return propType;
        })
        .filter(item => item !== '')
        .join(', ') || ''
    );
  };

  const constructGender = () => {
    return (
      gender
        ?.map(g => T(SEARCH_FILTER_GENDER_TRANSLATIONS[g]))
        .filter(item => item !== '')
        .join(', ') || ''
    );
  };

  const constructFurniture = () => {
    return (
      furniture
        ?.map(f => T(SEARCH_FILTER_FURNITURE_TRANSLATIONS[f]))
        .filter(item => item !== '')
        .join(', ') || ''
    );
  };

  const constructRegistration = () => {
    return registration?.includes('possible')
      ? T(SEARCH_FILTER_REGISTRATION_POSSIBLE_TRANSLATION)
      : '';
  };

  const constructSuitableFor = () => {
    return (
      suitableFor
        ?.map(sF => {
          if (!sF) return '';

          if (sF === SuitableForValue.COUPLES) {
            return T('Couples');
          }

          return T(SEARCH_FILTER_OCCUPATION_TRANSLATIONS[sF]);
        })
        .filter(item => item !== '')
        .join(', ') || ''
    );
  };

  const constructOccupationAndGender = () => {
    const genderFilters = constructGender();
    const occupationFilters = constructSuitableFor();

    const genderAndOccupationFilters =
      [genderFilters, occupationFilters]
        .filter(item => item !== '')
        .join(', ') || '';

    return genderAndOccupationFilters
      ? `${T(
          'search.filters.popup.header.suitable_for',
        ).toString()} ${genderAndOccupationFilters}`
      : '';
  };

  const constructSizeFilter = ({ isImperialSystem = false }) => {
    const unit = getAreaUnit({ isImperialSystem });
    const getSize = (size = 0) =>
      isImperialSystem ? convertSquareMeterToSquareFoot(size) : size;

    if (!sizeMin && !sizeMax) return '';

    if (sizeMin && !sizeMax) {
      return `${T('label.filter.minimum').toString()} ${getSize(
        sizeMin,
      )}${unit}`;
    }

    if (!sizeMin && sizeMax) {
      return `${T('label.filter.maximum').toString()} ${getSize(
        sizeMax,
      )}${unit}`;
    }

    if (sizeMin && sizeMax) {
      return [`${getSize(sizeMin)}${unit}`, `${getSize(sizeMax)}${unit}`].join(
        ' - ',
      );
    }

    return '';
  };

  const constructFacilitiesAndAmenities = () => {
    const amenitiesFilter = amenities?.map(a => {
      return T(SEARCH_FILTER_AMENITIES_TRANSLATIONS[a] as string);
    });

    const facilitiesFilter = facilities?.map(f => {
      return T(SEARCH_FILTER_FACILITIES_TRANSLATIONS[f] as string);
    });

    const facilitiesAndAmenities = facilitiesFilter
      ?.concat(amenitiesFilter || [])
      .filter(i => i !== '')
      .join(', ');

    return facilitiesAndAmenities || '';
  };

  const constructAdvertiserRating = () => {
    if (!advRating) return '';

    if (advRating === AdvertiserRatingValue.UNRATED) {
      return T(SEARCH_FILTER_ADVERTISER_RATING_TRANSLATIONS.unrated);
    }

    if (
      [
        AdvertiserRatingValue.OVER_THREE,
        AdvertiserRatingValue.OVER_FOUR,
      ].includes(advRating)
    ) {
      return `${T(
        'search.filters.popup.header.advertiser_rating',
      ).toString()} ${T(
        SEARCH_FILTER_ADVERTISER_RATING_TRANSLATIONS[advRating],
        SEARCH_FILTER_ADVERTISER_RATING_VALUES[advRating],
      ).toString()}`;
    }

    return '';
  };

  const constructContractType = () => {
    const contractTypeFilters = contractType
      ?.map(cT => T(SEARCH_FILTER_CONTRACT_TYPE_TRANSLATIONS[cT] as string))
      .filter(i => i !== '')
      .join(' ,');

    return contractTypeFilters
      ? `${T(
          'search.filters.popup.header.contract_type',
        ).toString()} ${contractTypeFilters}`
      : '';
  };

  return {
    constructAdvertiserRating,
    constructBillsIncluded,
    constructCategories,
    constructContractType,
    constructDateFilter,
    constructFacilitiesAndAmenities,
    constructFurniture,
    constructOccupationAndGender,
    constructPriceFilter,
    constructSizeFilter,
    constructRegistration,
  };
};
