import React from 'react';

import { useMediaQuery, ListItemButton } from '@mui/material';
import { useTheme } from '@mui/system';

import { scroller } from 'react-scroll';
import { makeStyles } from 'tss-react/mui';

import { Typography, Link } from '@hbf/dsl/core';
import { Grid } from '@hbf/dsl/legacy';
import { ChevronDown } from '@hbf/icons/brand-bold';

import { useConfig } from 'ha/helpers/config';
import { useIntl } from 'ha/i18n';

import { Imgix } from 'ha/modules/Image/Imgix';

import { HOME_IMGIX_PATH } from '../../constants';

import { POPULAR_CITIES } from './constants';
import { useListingsCount } from './useListingsCount';

const useStyles = makeStyles()(theme => ({
  container: {
    color: theme.palette.secondary.main,
    paddingBlock: theme.spacing(8),
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(9),
    },
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
  },
  title: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  citiesGrid: {
    width: '646px',
  },
  arrowIcon: {
    height: theme.spacing(4),
  },
  mapImage: {
    maxWidth: '100%',
  },
  paddingInlineMobile: {
    paddingInline: theme.spacing(5),
    [theme.breakpoints.up('md')]: {
      paddingInline: theme.spacing(8),
    },
  },
  cityListItem: {
    padding: theme.spacing(5),
    borderTop: `1px solid ${theme.palette.mono.dark}`,
    [theme.breakpoints.up('md')]: {
      paddingInline: theme.spacing(8),
    },
  },
  cityImg: {
    borderRadius: theme.spacing(1),
    boxShadow: `6px 6px 0px 0px ${theme.palette.mono.main}`,
    maxWidth: '100%',
  },
  gridContainer: {
    maxWidth: '100%',
    marginInline: 0,
  },
}));

const PopularCitiesCompact = () => {
  const { T, lang } = useIntl();
  const { classes } = useStyles();

  const { imgix } = useConfig();
  const imgPath = `${imgix.sourceURL}/${HOME_IMGIX_PATH}/`;
  const mapSrc = `${imgPath}/map.jpg`;

  const { getListingsCount, listingsCount } = useListingsCount();

  React.useEffect(() => {
    getListingsCount();
  }, [getListingsCount]);

  const scrollToTopCities = () => {
    scroller.scrollTo('homepage.top.cities', {
      duration: 800,
      offset: -15,
      smooth: true,
    });
  };

  return (
    <div className={classes.container}>
      <div className={classes.paddingInlineMobile}>
        <Typography variant="heading/mobile/h2" component="h3">
          {T('home.featured_cities.title')}
        </Typography>
      </div>
      <div>
        <Imgix
          src={mapSrc}
          sizes="100%"
          className={classes.mapImage}
          htmlAttributes={{ alt: 'Popular Cities', loading: 'lazy' }}
        />
      </div>
      <div className={classes.title}>
        <div className={classes.paddingInlineMobile}>
          <Typography variant="display/mobile/md">
            {T('home.featured_cities.number_cities')}
          </Typography>
        </div>
        <div className={classes.paddingInlineMobile}>
          <Typography variant="heading/mobile/h4-regular">
            {T('home.featured_cities.number_properties')}
          </Typography>
        </div>
      </div>
      <div>
        {POPULAR_CITIES.slice(0, 5).map(city => {
          return (
            <ListItemButton
              className={classes.cityListItem}
              href={city.urls[lang]}
              key={city.canonicalName}
            >
              <Grid container justifyContent="space-between" direction="row">
                <Grid item>
                  <Typography
                    variant="heading/desktop/h4-semibold"
                    component="p"
                  >
                    {T(city.name)}
                  </Typography>

                  <Typography variant="body/lg-regular" component="p">
                    {listingsCount?.[city.canonicalName]} {T('Listings')}
                  </Typography>
                </Grid>

                <Grid item>
                  <Imgix
                    className={classes.cityImg}
                    width={60}
                    src={`${imgPath}listing${city.canonicalName}.jpg`}
                    htmlAttributes={{ alt: city.name, loading: 'lazy' }}
                  />
                </Grid>
              </Grid>
            </ListItemButton>
          );
        })}
      </div>

      <div className={classes.paddingInlineMobile}>
        <Link
          size="sm"
          endIcon={<ChevronDown className={classes.arrowIcon} />}
          onClick={scrollToTopCities}
          underline="dotted"
        >
          {T('home.featured_cities.cta_new')}
        </Link>
      </div>
    </div>
  );
};
const PopularCitiesFull = () => {
  const { classes } = useStyles();
  const { T, lang } = useIntl();

  const { imgix } = useConfig();
  const mapSrc = `${imgix.sourceURL}/${HOME_IMGIX_PATH}/map.jpg`;

  const scrollToTopCities = () => {
    scroller.scrollTo('homepage.top.cities', {
      duration: 1000,
      offset: -25,
      smooth: true,
    });
  };

  return (
    <div className={classes.container}>
      <Grid
        className={classes.gridContainer}
        container
        direction="column"
        alignItems="center"
        spacing={7}
      >
        <Grid item>
          <Typography variant="heading/desktop/h2" component="h3">
            {T('home.featured_cities.title')}
          </Typography>
        </Grid>

        <Grid className={classes.citiesGrid} item container spacing={5}>
          {POPULAR_CITIES.map(city => (
            <Grid item xs={3} key={city.canonicalName}>
              <Link href={city.urls[lang]} size="lg" title={city.name}>
                {T(city.name)}
              </Link>
            </Grid>
          ))}
        </Grid>

        <Grid item>
          <Imgix
            src={mapSrc}
            width={620}
            htmlAttributes={{ alt: 'Popular Cities', loading: 'lazy' }}
          />
        </Grid>

        <Grid
          item
          container
          direction="column"
          spacing={3}
          xs="auto"
          alignItems="center"
        >
          <Grid item>
            <Typography variant="display/desktop/lg">
              {T('home.featured_cities.number_cities')}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="heading/desktop/h4-regular">
              {T('home.featured_cities.number_properties')}
            </Typography>
          </Grid>
        </Grid>

        <Grid item>
          <Link
            size="md"
            underline="dotted"
            endIcon={<ChevronDown className={classes.arrowIcon} />}
            onClick={scrollToTopCities}
          >
            {T('home.featured_cities.cta_new')}
          </Link>
        </Grid>
      </Grid>
    </div>
  );
};

export const PopularCities = () => {
  const theme = useTheme();
  const isLargerThanMd = useMediaQuery(theme.breakpoints.up('md'));

  if (isLargerThanMd) return <PopularCitiesFull />;
  return <PopularCitiesCompact />;
};
