/* eslint-disable react/require-default-props */
import React from 'react';

import { useMediaQuery, useTheme } from '@mui/material';

import {
  ListingPricingType,
  ListingPricingValues,
  ListingRentalPeriodType,
} from '@ha/api/v2/types/Listing';
import { Typography } from '@hbf/dsl/core';

import { getPrecisePriceRange } from 'ha/helpers/getPrecisePrices';
import { isEnabled, useFeatureFlags } from 'ha/modules/FeatureFlags';

import { PricingSectionContext, PricingSectionProvider } from './context';
import { PricingSectionProps } from './types';

const PriceLabel = ({
  minPrice,
  maxPrice,
  testId,
}: {
  minPrice: number;
  maxPrice?: number;
  testId?: string;
}) => {
  const { formatPrice } = PricingSectionContext.useContext();

  const shouldShowPriceRange = maxPrice && maxPrice !== minPrice;
  const theme = useTheme();
  const isLargerThanMd = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Typography
      variant={
        isLargerThanMd
          ? 'heading/desktop/h4-semibold'
          : 'heading/mobile/h4-semibold'
      }
      color="secondary"
      data-test-locator={`ListingCard.PriceLabel${testId ? `.${testId}` : ''}`}
    >
      {formatPrice(minPrice)}
      {shouldShowPriceRange && ` - ${formatPrice(maxPrice)}`}
    </Typography>
  );
};

const PricingSectionStrictRentalPricing = () => {
  const { room, queryParams } = PricingSectionContext.useContext();
  if (!room || room.minPrice === undefined) return null;

  if (
    room.minPrice === 0 ||
    room.minPrice === room.maxPrice ||
    queryParams?.startDate
  ) {
    return <PriceLabel minPrice={room.price} testId="StrictRentalPricing" />;
  }

  return (
    <PriceLabel
      minPrice={room.minPrice * 100}
      maxPrice={room.maxPrice && room.maxPrice * 100}
      testId="StrictRentalPricing"
    />
  );
};

const PricingSectionPrecisePricing = () => {
  const { room, queryParams, parseDateFromQueryParams } =
    PricingSectionContext.useContext();
  if (!room || !room?.priceType || !room?.pricingValuesEUR?.precise) {
    return null;
  }

  const parsedDate = parseDateFromQueryParams(queryParams);

  const precisePriceRange = getPrecisePriceRange({
    pricingType: room?.priceType as ListingPricingType,
    pricingValues: room?.pricingValuesEUR as ListingPricingValues,
    dateRange: {
      from: parsedDate.startDate,
      to: parsedDate.endDate,
    },
  });

  if (precisePriceRange?.from) {
    return (
      <PriceLabel
        minPrice={precisePriceRange.from * 100}
        maxPrice={precisePriceRange.to * 100}
        testId="PrecisePricing"
      />
    );
  }

  return null;
};

const PricingSectionDynamicMinPricing = () => {
  const { room } = PricingSectionContext.useContext();
  if (!room || !room?.minPrice) return null;

  return (
    <PriceLabel
      minPrice={room.minPrice * 100}
      maxPrice={room.maxPrice && room.maxPrice * 100}
      testId="DynamicMinPricing"
    />
  );
};

export const PricingSection: React.FC<PricingSectionProps> = props => {
  const { withDynamicMinimumPrice, strictRentalPeriodSearch } =
    useFeatureFlags();

  const pricingComponent = React.useMemo(() => {
    // Strict Rental Period
    const isStrictRentalPeriod =
      isEnabled(strictRentalPeriodSearch) &&
      props.room.rentalPeriodType === ListingRentalPeriodType.STRICT;
    if (isStrictRentalPeriod && props.room.minPrice) {
      return <PricingSectionStrictRentalPricing />;
    }

    //  Precise Pricing (Discounted Pricing)
    const hasPrecisePrices =
      Object.keys(props.room?.pricingValuesEUR?.precise || {}).length > 0;
    if (hasPrecisePrices) return <PricingSectionPrecisePricing />;

    // Dynamic Minimum Pricing
    if (
      isEnabled(withDynamicMinimumPrice) &&
      props.room.priceType === 'monthly' &&
      props.room.minPrice
    ) {
      return <PricingSectionDynamicMinPricing />;
    }

    // Default Price
    return <PriceLabel minPrice={props.room.price} />;
  }, [props.room, strictRentalPeriodSearch, withDynamicMinimumPrice]);

  return (
    <PricingSectionProvider {...props}>
      {pricingComponent}
    </PricingSectionProvider>
  );
};
