import dayjs from 'dayjs';

import {
  ListingPricingType,
  ListingPricingValues,
  PricingMonthlyKeys,
} from '@ha/api/v2/types/Listing';
import { getAllDatesBetweenTwoDates } from '@ha/date';

interface GetAllRelevantPrecisePricesProps {
  /** Object with Precise Pricing for each month. eg. { "12-2023": 300, "01-2024": 600, ... } */
  precisePrices: ListingPricingValues['precise'];
  /** Pass in start and end dates to get a list of precise prices that are available in that range in `precisePrices` object */
  dateFilter?: {
    from: string | Date;
    to?: string | Date;
  };
}

export const getAllRelevantPrices = ({
  pricingValues,
  pricingType,
  dateFilter,
}: {
  pricingValues: ListingPricingValues;
  pricingType: ListingPricingType;
  dateFilter: GetAllRelevantPrecisePricesProps['dateFilter'];
}) => {
  if (!dateFilter?.from) return [];

  const allDates = getAllDatesBetweenTwoDates({
    startDate: new Date(dateFilter.from),
    endDate: dateFilter.to
      ? new Date(dateFilter.to)
      : dayjs(dateFilter.from).add(12, 'months').toDate(),
  });

  return allDates.flatMap(date => {
    const isMonthlyPriceType = pricingType === ListingPricingType.MONTHLY;

    // Precise Price
    const preciseDateKey = dayjs(date).format('MM-YYYY');
    const selectedDatePrecisePrice =
      pricingValues?.precise?.[preciseDateKey] ?? 0;

    if (isMonthlyPriceType && selectedDatePrecisePrice) {
      return [selectedDatePrecisePrice];
    }

    // Monthly Price
    const monthDateKey = `m-${dayjs(date).format('MM')}` as PricingMonthlyKeys;
    const selectedDateMonthlyPrice = pricingValues.monthly?.[monthDateKey] ?? 0;

    if (isMonthlyPriceType && selectedDateMonthlyPrice) {
      return [selectedDateMonthlyPrice];
    }

    // Flat Price
    const flatPrice = pricingValues.flat ?? 0;

    return [selectedDatePrecisePrice || flatPrice];
  });
};

interface GetPrecisePriceRangeProps {
  pricingValues: ListingPricingValues;
  pricingType: ListingPricingType;
  dateRange?: GetAllRelevantPrecisePricesProps['dateFilter'];
}

export const getPrecisePriceRange = ({
  pricingType,
  pricingValues,
  dateRange,
}: GetPrecisePriceRangeProps) => {
  if (!pricingValues?.precise) return null;

  const relevantPrices = getAllRelevantPrices({
    pricingType,
    pricingValues,
    dateFilter: dateRange,
  });

  if (!relevantPrices.length) return null;

  const minPrecisePrice = Math.min(...relevantPrices);
  const maxPrecisePrice = Math.max(...relevantPrices);

  const priceRange = {
    from: minPrecisePrice,
    to: maxPrecisePrice,
  };

  return {
    ...priceRange,
    type: priceRange.from === priceRange.to ? 'flatPrice' : 'fromPrice',
  };
};
