import { PropertyType } from '@ha/algolia';
import {
  ListingKind,
  ListingType,
  ListingState,
} from '@ha/api/v2/types/Listing';
import { defineKey } from '@ha/intl';

import { TypeValue } from 'ha/types/SearchFilters';

export const ListingTypeTranslations: Record<ListingType, string> = {
  [ListingType.House]: defineKey('House'),
  [ListingType.Building]: defineKey('Building'),
  [ListingType.Apartment]: defineKey('Apartment'),
};

export const ListingKindTranslations: Record<ListingKind, string> = {
  [ListingKind.EntirePlace]: defineKey('Entire place'),
  [ListingKind.PrivateRoom]: defineKey('Private room'),
  [ListingKind.SharedRoom]: defineKey('Shared room'),
};

export const ListingStateTranslations: Record<ListingState, string> = {
  [ListingState.DRAFT]: defineKey('Draft'),
  [ListingState.PUBLISHED]: defineKey('Published'),
  [ListingState.UNPUBLISHED]: defineKey('Unpublished'),
};

export const PropertyTypeTranslations: Record<PropertyType, string> = {
  [PropertyType.house]: defineKey('House'),
  [PropertyType.building]: defineKey('Building'),
  [PropertyType.apartment]: defineKey('Apartment'),
  [PropertyType.studio]: defineKey('Studio'),
  [PropertyType.privateRoom]: defineKey('Private room'),
  [PropertyType.sharedRoom]: defineKey('Shared room'),
  [PropertyType.unknown]: defineKey('Unknown'),
};

export const EntirePlaceTypeTranslations: Record<ListingType, string> = {
  [ListingType.House]: defineKey('Entire house'),
  [ListingType.Building]: defineKey('Entire building'),
  [ListingType.Apartment]: defineKey('Entire apartment'),
};

export const CategoriesTranslations: Record<TypeValue | '', string> = {
  [TypeValue.PRIVATE_ROOM]: defineKey('Rooms'),
  [TypeValue.SHARED_ROOM]: defineKey('Rooms'),
  [TypeValue.APARTMENT]: defineKey('Apartments'),
  [TypeValue.STUDIO]: defineKey('Studio'),
  [TypeValue.STUDENT_HOUSING]: defineKey(
    'student_accommodation.search_page.label',
  ),
  '': defineKey('Accommodation'),
};
