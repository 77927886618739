import React from 'react';

import { Link, Typography } from '@hbf/dsl/core';

import { AuthPageQueryParams, AuthPageTarget } from 'ha/helpers/UrlResolver';
import { useIntl } from 'ha/i18n';

import { usePage } from 'ha/modules/Page/contexts';

import { BasePlaceHolderLayout } from './BasePlaceHolderLayout';

export const FavoriteListingsUnauthenticatedPlaceHolder = () => {
  const { urlResolver, T } = useIntl();

  const { authLinkInfo } = usePage();
  const { continueURL, queryParams } = authLinkInfo;

  const authUrlQueryParams = React.useMemo(() => {
    const params: AuthPageQueryParams = {
      target: queryParams?.target || AuthPageTarget.Tenant,
    };

    return params;
  }, [queryParams?.target]);

  const signUpUrl = urlResolver.getOAuthSignupUrl(
    continueURL,
    authUrlQueryParams,
  );

  const signInUrl = urlResolver.getOAuthSigninUrl(
    continueURL,
    authUrlQueryParams,
  );

  return (
    <BasePlaceHolderLayout data-test-locator="FavoriteListingsUnauthenticatedPlaceHolder">
      <div>
        <Link href={signUpUrl} size="lg" underline="always">
          {T('Sign up')}
        </Link>{' '}
        <Typography variant="body/lg-regular">
          {T('recent_searches.tabs.not_logged_in.sign_up')}
        </Typography>
      </div>
      <div>
        <Typography variant="body/lg-regular">
          {T('Already have an account?')}
        </Typography>{' '}
        <Link href={signInUrl} size="lg" underline="always">
          {T('Login')}
        </Link>
      </div>
    </BasePlaceHolderLayout>
  );
};
