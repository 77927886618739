import { ListingCostsCosts } from '@ha/api/v2/types/Listing';
import { ListingDepositType } from 'ha/components/Redesign/Listing/constants';

export function getListingDepositType(
  costs?: ListingCostsCosts,
): ListingDepositType | undefined {
  if (!costs) return undefined;

  const securityDeposit = costs['security-deposit'];

  if (!securityDeposit) return ListingDepositType.NO_DEPOSIT;

  if (securityDeposit.required) return ListingDepositType.DEPOSIT_REQUIRED;

  return ListingDepositType.DEPOSIT_OPTIONAL;
}
