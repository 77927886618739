import React from 'react';

import { Skeleton, useMediaQuery } from '@mui/material';

import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  root: {
    display: 'grid',
    gap: theme.utils.spacing('ref/spacing/6'),
    gridTemplateColumns: 'repeat(1, 1fr)',

    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: 'repeat(3, 1fr)',
    },
    [theme.breakpoints.up('lg')]: {
      gap: theme.utils.spacing('ref/spacing/10'),
      gridTemplateColumns: 'repeat(4, 1fr)',
    },
  },
  textBlock: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.utils.spacing('ref/spacing/2'),
    gap: theme.utils.spacing('ref/spacing/3'),
  },
}));

export const ListingsSliderSkeleton = () => {
  const { classes, theme } = useStyles();
  const isLargerThanLg = useMediaQuery(theme.breakpoints.up('lg'));
  const isLargerThanMd = useMediaQuery(theme.breakpoints.up('md'));
  const isLargerThanSm = useMediaQuery(theme.breakpoints.up('sm'));

  const sliderPerView = React.useMemo(() => {
    if (isLargerThanLg) return 4;
    if (isLargerThanMd) return 3;
    if (isLargerThanSm) return 2;

    return 1;
  }, [isLargerThanLg, isLargerThanMd, isLargerThanSm]);

  const placeholderHeight = React.useMemo(() => {
    if (isLargerThanMd) return 358;
    return 250;
  }, [isLargerThanMd]);

  return (
    <div className={classes.root} data-test-locator="ListingsSliderSkeleton">
      {Array.from({ length: sliderPerView }, (_, index) => (
        <div key={index}>
          <Skeleton variant="rectangular" height={placeholderHeight} />
          <div className={classes.textBlock}>
            <Skeleton variant="rectangular" height={25} />
            <Skeleton variant="rectangular" height={50} />
            <Skeleton variant="rectangular" height={20} width="50%" />
          </div>
        </div>
      ))}
    </div>
  );
};
