import { defineKey as T } from '@ha/intl';

export const POPULAR_CITIES = [
  {
    name: T('Berlin'),
    canonicalName: 'Berlin',
    urls: {
      en: '/s/Berlin--Germany',
      fr: '/fr/s/Berlin--Allemagne',
      de: '/de/s/Berlin--Deutschland',
      es: '/es/s/Berlín--Alemania',
      it: '/it/s/Berlino--Germania',
      nl: '/nl/s/Berlijn--Duitsland',
      pt: '/pt/s/Berlim--Alemanha',
      zh: '/zh/s/柏林--德国',
      uk: '/uk/s/Берлін--Німеччина',
      ru: '/ru/s/Берлин--Германия',
      pl: '/pl/s/Berlin--Niemcy',
      sv: '/sv/s/Berlin--Tyskland',
      ro: '/ro/s/Berlin--Ӂермания',
    },
  },
  {
    name: T('Rotterdam'),
    canonicalName: 'Rotterdam',
    urls: {
      en: '/s/Rotterdam--Netherlands',
      fr: '/fr/s/Rotterdam--Pays-Bas',
      de: '/de/s/Rotterdam--Niederlande',
      es: '/es/s/Róterdam--Holanda',
      it: '/it/s/Rotterdam--Olanda',
      nl: '/nl/s/Rotterdam--Nederland',
      pt: '/pt/s/Roterdão--Países-Baixos',
      zh: '/zh/s/鹿特丹--荷兰',
      uk: '/uk/s/Роттердам--Нідерланди',
      ru: '/ru/s/Роттердам--Нидерланды',
      pl: '/pl/s/Rotterdam--Holandia',
      sv: '/sv/s/Rotterdam--Nederländerna',
      ro: '/ro/s/Rotterdam--Țările-de-Jos',
    },
  },
  {
    name: T('Vienna'),
    canonicalName: 'Vienna',
    urls: {
      en: '/s/Vienna--Austria',
      fr: '/fr/s/Vienne--Autriche',
      de: '/de/s/Wien--Österreich',
      es: '/es/s/Viena--Austria',
      it: '/it/s/Vienna--Austria',
      nl: '/nl/s/Wenen--Oostenrijk',
      pt: '/pt/s/Viena--Áustria',
      zh: '/zh/s/Vienna--奥地利',
      uk: '/uk/s/Відень--Австрія',
      ru: '/ru/s/Вена--Австрия',
      pl: '/pl/s/Wiedeń--Austria',
      sv: '/sv/s/Wien--Österrike',
      ro: '/ro/s/Viena--Austria',
    },
  },
  {
    name: T('Brussels'),
    canonicalName: 'Brussels',
    urls: {
      en: '/s/Brussels--Belgium',
      fr: '/fr/s/Bruxelles--Belgique',
      de: '/de/s/Brüssel--Königreich-Belgien',
      es: '/es/s/Bruselas--Bélgica',
      it: '/it/s/Bruxelles--Belgio',
      nl: '/nl/s/Brussel--België',
      pt: '/pt/s/Bruxelas--Bélgica',
      zh: '/zh/s/Brussels--比利时',
      uk: '/uk/s/Брюссель--Бельгія',
      ru: '/ru/s/Брюссель--Бельгия',
      pl: '/pl/s/Bruksela--Belgia',
      sv: '/sv/s/Bryssel--Belgien',
      ro: '/ro/s/Bruxelles--Belgia',
    },
  },
  {
    name: T('Barcelona'),
    canonicalName: 'Barcelona',
    urls: {
      en: '/s/Barcelona--Spain',
      fr: '/fr/s/Barcelone--Espagne',
      de: '/de/s/Barcelona--Spanien',
      es: '/es/s/Barcelona--España',
      it: '/it/s/Barcellona--Spagna',
      nl: '/nl/s/Barcelona--Spanje',
      pt: '/pt/s/Barcelona--Espanha',
      zh: '/zh/s/巴塞罗那--西班牙',
      uk: '/uk/s/Барселона--Іспанія',
      ru: '/ru/s/Барселона--Испания',
      pl: '/pl/s/Barcelona--Hiszpania',
      sv: '/sv/s/Barcelona--Spanien',
      ro: '/ro/s/Barcelona--Spania',
    },
  },
  {
    name: T('Milan'),
    canonicalName: 'Milan',
    urls: {
      en: '/s/Milan--Italy',
      fr: '/fr/s/Milan--Italie',
      de: '/de/s/Mailand--Italien',
      es: '/es/s/Milán--Italia',
      it: '/it/s/Milano--Italia',
      nl: '/nl/s/Milaan--Italië',
      pt: '/pt/s/Milão--Itália',
      zh: '/zh/s/Milan--意大利',
      uk: '/uk/s/Мілан--Італія',
      ru: '/ru/s/Милан--Италия',
      pl: '/pl/s/Mediolan--Włochy',
      sv: '/sv/s/Milano--Italien',
      ro: '/ro/s/Milano--Italia',
    },
  },
  {
    name: T('Madrid'),
    canonicalName: 'Madrid',
    urls: {
      en: '/s/Madrid--Spain',
      fr: '/fr/s/Madrid--Espagne',
      de: '/de/s/Madrid--Spanien',
      es: '/es/s/Madrid--España',
      it: '/it/s/Madrid--Spagna',
      nl: '/nl/s/Madrid--Spanje',
      pt: '/pt/s/Madrid--Espanha',
      zh: '/zh/s/马德里--西班牙',
      uk: '/uk/s/Мадрид--Іспанія',
      ru: '/ru/s/Мадрид--Испания',
      pl: '/pl/s/Madryt--Hiszpania',
      sv: '/sv/s/Madrid--Spanien',
      ro: '/ro/s/Madrid--Spania',
    },
  },
  {
    name: T('London'),
    canonicalName: 'London',
    urls: {
      en: '/s/London--United-Kingdom',
      fr: '/fr/s/Londres--Royaume-Uni',
      de: '/de/s/London--Vereinigtes-Königreich',
      es: '/es/s/Londres--Reino-Unido',
      it: '/it/s/Londra--Regno-Unito',
      nl: '/nl/s/Londen--Verenigd-Koninkrijk',
      pt: '/pt/s/Londres--Reino-Unido',
      zh: '/zh/s/伦敦--英国',
      uk: '/uk/s/Лондон--Сполучене-Королівство',
      ru: '/ru/s/Лондон--Великобритания',
      pl: '/pl/s/Londyn--Zjednoczone-Królestwo-Wielkiej-Brytanii',
      sv: '/sv/s/London--Storbritannien',
      ro: '/ro/s/Londra--Marea-Britanie',
    },
  },
  {
    name: T('Paris'),
    canonicalName: 'Paris',
    urls: {
      en: '/s/Paris--France',
      fr: '/fr/s/Paris--France',
      de: '/de/s/Paris--Frankreich',
      es: '/es/s/París--Francia',
      it: '/it/s/Parigi--Francia',
      nl: '/nl/s/Parijs--Frankrijk',
      pt: '/pt/s/Paris--França',
      zh: '/zh/s/巴黎--法国',
      uk: '/uk/s/Париж--Франція',
      ru: '/ru/s/Париж--Франция',
      pl: '/pl/s/Paryż--Francja',
      sv: '/sv/s/Paris--Frankrike',
      ro: '/ro/s/Paris--Franța',
    },
  },
  {
    name: T('in_city.new_york'),
    canonicalName: 'New York',
    urls: {
      en: '/s/New-York--United-States',
      fr: '/fr/s/New-York--États-Unis',
      de: '/de/s/New-York-City--USA',
      es: '/es/s/Nueva-York--Estados-Unidos',
      it: '/it/s/New-York--Stati-Uniti',
      nl: '/nl/s/New-York--Verenigde-Staten',
      pt: '/pt/s/Nova-Iorque--Estados-Unidos',
      zh: '/zh/s/纽约--美国',
      uk: '/uk/s/Нью-Йорк--Сполучені-Штати-Америки',
      ru: '/ru/s/Нью-Йорк--США',
      pl: '/pl/s/Nowy-Jork--Stany-Zjednoczone',
      sv: '/sv/s/New-York--Amerikas-Förenta-Stater',
      ro: '/ro/s/New-York--Statele-Unite-ale-Americii',
    },
  },
  {
    name: T('in_city.los_angeles'),
    canonicalName: 'Los Angeles',
    urls: {
      en: '/s/Los-Angeles--United-States',
      fr: '/fr/s/Los-Angeles--États-Unis',
      de: '/de/s/Los-Angeles--USA',
      es: '/es/s/Los-Ángeles--Estados-Unidos',
      it: '/it/s/Los-Angeles--Stati-Uniti',
      nl: '/nl/s/Los-Angeles--Verenigde-Staten',
      pt: '/pt/s/Los-Angeles--Estados-Unidos',
      zh: '/zh/s/洛杉矶--美国',
      uk: '/uk/s/Лос-Анджелес--Сполучені-Штати-Америки',
      ru: '/ru/s/Лос-Анджелес--США',
      pl: '/pl/s/Los-Angeles--Stany-Zjednoczone',
      sv: '/sv/s/Los-Angeles--Amerikas-Förenta-Stater',
      ro: '/ro/s/Лос-Анӂелес--Statele-Unite-ale-Americii',
    },
  },
];
