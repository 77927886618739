import { defineKey } from '@ha/intl';

import { SuitableForValue } from 'ha/types/SearchFilters';

export const SEARCH_FILTER_OCCUPATION = [
  SuitableForValue.STUDENTS,
  SuitableForValue.WORKING_PROFESSIONALS,
] as const;

export const SEARCH_FILTER_OCCUPATION_TRANSLATIONS = {
  [SuitableForValue.STUDENTS]: defineKey('search.filters.popup.label.students'),
  [SuitableForValue.WORKING_PROFESSIONALS]: defineKey(
    'search.filters.popup.label.professionals',
  ),
} satisfies Record<(typeof SEARCH_FILTER_OCCUPATION)[number], string>;
