import { constants as commonConstants } from 'ha/constants/commonConstants';
import { addMonths, diffDay } from '@ha/date';

const { listingFlexibleDays } = commonConstants;

const calculateMinimumSelectedDays = (startDate, minimalPeriod) => {
  if (!minimalPeriod) {
    return 0;
  }

  return (
    diffDay(addMonths(startDate, minimalPeriod), startDate) -
    listingFlexibleDays
  );
};

export { calculateMinimumSelectedDays };
