import React from 'react';
import { createRequiredContext } from 'ha/core/RequiredContext';
import { useIntl } from 'ha/i18n';
import { CurrencyFormat } from '@ha/intl';
import { PricingSectionProps } from './types';

type ParsedDateFromQueryParams = {
  startDate: string | Date;
  endDate: string | Date;
};

const parseDateFromQueryParams = (
  queryParams: PricingSectionContextProps['queryParams'],
) => {
  const parsedDates: ParsedDateFromQueryParams = {
    startDate: new Date(),
    endDate: '',
  };

  if (!queryParams) return parsedDates;

  if (queryParams.startDate && typeof queryParams.startDate === 'string') {
    parsedDates.startDate = queryParams.startDate;
  }

  if (queryParams?.endDate && typeof queryParams?.endDate === 'string') {
    parsedDates.endDate = queryParams.endDate;
  }

  return parsedDates;
};

interface PricingSectionContextProps extends PricingSectionProps {
  formatPrice: (price: number) => string;
  parseDateFromQueryParams: (
    queryParams: PricingSectionContextProps['queryParams'],
  ) => { startDate: string | Date; endDate: string | Date };
}

export const PricingSectionContext =
  createRequiredContext<PricingSectionContextProps>(
    'ListingCard.PricingSectionContext',
  );

export const PricingSectionProvider: React.FC<
  React.PropsWithChildren<PricingSectionProps>
> = ({ children, ...props }) => {
  const { formatCurrency } = useIntl();
  const formatPrice = React.useCallback(
    (price: number) => {
      const currencyFormat = ['EUR', 'USD'].includes(props.room.currency)
        ? CurrencyFormat.WITH_CURRENCY_SYMBOL_NO_FRACTION_NO_GROUPING
        : CurrencyFormat.WITH_CURRENCY_CODE_NO_FRACTION_NO_GROUPING;

      return formatCurrency(price, props.room.currency, currencyFormat);
    },
    [props.room.currency, formatCurrency],
  );

  const providerProps = React.useMemo(
    () => ({ ...props, formatPrice, parseDateFromQueryParams }),
    [props, formatPrice],
  );

  return (
    <PricingSectionContext.Provider value={providerProps}>
      {children}
    </PricingSectionContext.Provider>
  );
};
