import React from 'react';
import { makeStyles } from 'tss-react/mui';

import { Typography, Accordion, AccordionProps } from '@hbf/dsl/core';
import { ChevronDown } from '@hbf/icons/brand-regular';
import { useMediaQuery, useTheme } from '@mui/material';

const useStyles = makeStyles()(theme => ({
  container: {
    backgroundColor: theme.palette.primary.main,
  },
  title: {
    color: theme.palette.secondary.main,
  },
  accordionIcon: {
    color: theme.palette.secondary.main,
  },
}));

export const Question = ({ children }: React.PropsWithChildren) => {
  const { classes } = useStyles();

  const theme = useTheme();

  const isLargerThanMd = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Accordion.Summary
      expandIcon={
        <ChevronDown className={classes.accordionIcon} fontSize="medium" />
      }
    >
      <Typography
        variant={
          isLargerThanMd
            ? 'heading/desktop/h4-semibold'
            : 'heading/mobile/h4-semibold'
        }
      >
        {children}
      </Typography>
    </Accordion.Summary>
  );
};

export const Answer = ({ children }: React.PropsWithChildren) => {
  return (
    <Accordion.Details>
      <Typography variant="body/lg-regular">{children}</Typography>
    </Accordion.Details>
  );
};

export const FAQSectionItem = ({ children }: AccordionProps) => {
  return <Accordion>{children}</Accordion>;
};

FAQSectionItem.Question = Question;
FAQSectionItem.Answer = Answer;
